import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/shared-nf-component/base-component';
import { JobAnnouncement } from 'src/app/model/job-announcement';
import { JobAnnouncementDataService } from 'src/app/services/data/job-announcement-data.service';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { LoadDataService } from 'src/app/services/data/load-data.service';
import { FormControl } from '@angular/forms';
import {MatSort, Sort} from '@angular/material/sort';
import { Collectivity } from 'src/app/model/collectivity';
import { Region } from 'src/app/model/region';

@Component({
  selector: 'app-find-job-announcement-internal',
  templateUrl: './find-job-announcement-internal.component.html',
  styleUrls: ['./find-job-announcement-internal.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
})
export class FindJobAnnouncementInternalComponent extends BaseComponent implements OnInit  {

  jobAnnouncementDataSource = new MatTableDataSource<JobAnnouncement>();
  @ViewChild("jobAnnouncementPaginator") paginator: MatPaginator;
  jobAnnouncementdisplayedColumns: string[] = jobAnnouncementColumns.map((col) => col.key);
  columnsToDisplayWithExpand = [...this.jobAnnouncementdisplayedColumns, 'expand'];
  jobAnnouncementcolumnsSchema: any = jobAnnouncementColumns;
  expandedElement: JobAnnouncement | null;

  valid: any = {};

  successMessageDelete = false;
  errorMessageDelete = false;
  message:string

  regions = new Map<string, string>([]);
  showInternal = false; 
  myControl = new FormControl('');
  @ViewChild(MatSort) sort: MatSort;
  jobAnnouncementList;

  constructor ( public dialog: MatDialog, 
                private jobAnnouncementService: JobAnnouncementDataService, 
                public loadDataService: LoadDataService,
                private router : Router,
                protected route: ActivatedRoute,               
                public data: ShareDataService ) { 
                  super(data);
                }

  ngOnInit() {

      // if pilote is connected and try to find jobAnnoucnement details 
      this.jobAnnouncementdisplayedColumns = jobAnnouncementColumns.map((col) => col.key);
      this.columnsToDisplayWithExpand = [...this.jobAnnouncementdisplayedColumns, 'expand'];
      this.jobAnnouncementcolumnsSchema = jobAnnouncementColumns;
      this.showInternal = true;

      this.jobAnnouncementDataSource.data = [];
      this.jobAnnouncementDataSource.paginator = this.paginator;
      this.myControl.valueChanges.subscribe( (findStr) => {
        this.findJobWith (findStr);
      });

      // Get all regions and put them as a map 
      // this.loadDataService.loadRegions().then ((regions) => {
        // regions.map(region => {
        //   this.regions.set(region.code, region.nom);
        // });
        
      // })
      
      this.findJobWith("");

  }

  findJobWith ( str : string ) {
      this.jobAnnouncementService.findJobAnnouncementsForPilote('', str,'' , '', '', 'true').subscribe((res: any) => {
        // res.forEach ((jobAnnouncement)  => {
        //     jobAnnouncement.region = this.setRightRegionName (jobAnnouncement.region); 
        //   });
          res.sort((first, second) => {
            if (first.startingdate == null || second.startingdate == null) {
              return -1;
            }
            return new Date(second.startingdate).getTime() - new Date(first.startingdate).getTime();
          });
          this.jobAnnouncementList = res;
          this.jobAnnouncementDataSource.data = res;
          this.jobAnnouncementDataSource.paginator = this.paginator;
          this.jobAnnouncementDataSource.sort = this.sort;
      });
  }

  setRightRegionName (r) : string  {
    return this.regions.get(r);
  }

  ngAfterViewInit() {
    this.jobAnnouncementDataSource.paginator = this.paginator;
  }

  createNewQuotation(element) {
    this.router.navigate(['my-home/create-quotation/job-announcement/', element.id]);
  }

  editQuotation (element) {
    this.router.navigate([`my-home/edit-quotation/job-announcement/${element.id}/quotation/${element.quotation.id}`]);
  }

  onSelectCollectivity(event) {
      var collectivity = event as Collectivity;
      var tags = '', region= '', department= '', zipcode : Array<string> = null, city= '';
      
      if (collectivity.type === 'region')
      {
        region = collectivity.zipcode[0]
      }
      else {
        if (collectivity.type === 'dept')
        {
          department = collectivity.zipcode[0]
        } 
        else
        {
          zipcode = collectivity.zipcode
        }
      }
    
      this.jobAnnouncementService
            .findJobAnnouncementsForPilote(tags, region, department, zipcode, city, 'true').subscribe((res: any) => {
              res.sort((first, second) => {
                if (first.startingdate == null || second.startingdate == null) {
                  return -1;
                }
                return new Date(second.startingdate).getTime() - new Date(first.startingdate).getTime();
              });
              this.jobAnnouncementList = res;
              this.jobAnnouncementDataSource.data = res;
              this.jobAnnouncementDataSource.paginator = this.paginator;
              this.jobAnnouncementDataSource.sort = this.sort;
      });
  }

  onSelectRegion(event) {
    var region = event as Region;
    this.jobAnnouncementService
      .findJobAnnouncements('', region.code, '', '', '', 'true').subscribe((res: any) => {
          // res.forEach ((jobAnnouncement)  => {
          //     jobAnnouncement.region = this.setRightRegionName (jobAnnouncement.region); 
          // });
          res.sort((first, second) => {
            if (first.startingdate == null || second.startingdate == null) {
              return -1;
            }
            return new Date(second.startingdate).getTime() - new Date(first.startingdate).getTime();
          });
          this.jobAnnouncementList = res;
          this.jobAnnouncementDataSource.data = res;
          this.jobAnnouncementDataSource.paginator = this.paginator;
          this.jobAnnouncementDataSource.sort = this.sort;
    });
  }

  isQuotationInProgress (element) {
    if (element.quotation === null || element.quotation === undefined)
      return false;
    return element.quotation.quotationref ? true : false;
  }
  /**
   * activer/désactiver la ligne selon si Nous voulons ou pas griser le bouton editer 
   * A la base cette fonctionnalité n'était autorisée que pour les devis qui ne sont pas expirée 
   * on s est rendu compte que si le dvis expire on ne peut plus postuler sur l annonce
   * ==> on la donc ouvert la mise à jours à tous les états de devis  
   * @param element 
   * @returns 
   */
  isEditableQuotation (element) {
    //return element.quotation.status === 'CREATED' ? true : false;
    return true;
  }

  expandOrCollapse (element) {
    this.expandedElement = this.expandedElement === element ? null : element;
  }
}

export const jobAnnouncementColumns = [
  {
    key: 'title',
    type: 'text',
    label: 'Titre',
    required: true,
  },
  {
    key: 'city',
    type: 'text',
    label: 'Ville',
  },
  {
    key: 'startingdate',
    type: 'date',
    label: 'Date Début',
  },
  {
    key: 'quotationinprogress',
    type: 'quotationinprogress',
    label: 'Devis',
  },
  {
    key: 'quotation',
    type: 'button',
    label: 'Créer devis',
  }
];


import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { CaptchaComponent } from './components/captcha/captcha.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AngularMatFormModule } from './modules/mat-form.modules';
import { LoginComponent } from './components/login/login.component';
import { UserHomeComponent } from './components/user/user-home/user-home.component';
import { SideNavbarComponent } from './components/user/side-navbar/side-navbar.component';
import { TopNavbarComponent } from './components/user/top-navbar/top-navbar.component';
import { SidenavbarService } from './services/side-navbar/side-navbar.service';
import { DashboardComponent } from './components/user/dashboard/dashboard.component';
import { ProfileComponent } from './components/user/profile/profile.component';
import { BarChartComponent } from './components/shared-component/charts/bar-chart/bar-chart.component';
import { LineChartComponent } from './components/shared-component/charts/line-chart/line-chart.component';
import { JobAnnouncementListComponent } from './components/user/job-announcement/job-announcement-list/job-announcement-list.component';
import { JobAnnoncementComponent } from './components/user/job-announcement/job-announcement-crud/job-announcement.component';
import { BreadcrumbComponent } from './components/user/shared-components/breadcrumb/breadcrumb.component';
import { CompanyComponent } from './components/user/company/company.component';
import { MaterialCrudComponent } from './components/user/pilot-material/material-crud/material-crud.component';
import { MaterialListComponent } from './components/user/pilot-material/material-list/material-list.component';
import { FindPilotComponent } from './components/shared-component/finders/find-pilot/find-pilot.component';
import { FindJobAnnouncementComponent } from './components/shared-component/finders/find-job-announcement/find-job-announcement.component';
import { CityComponent } from './components/shared-component/cities/city/city.component';
import { CityNameComponent } from './components/shared-component/cities/city-name/city-name.component';
import { CityZipcodeComponent } from './components/shared-component/cities/city-zipcode/city-zipcode.component';
import { CollectivityPrefixComponent } from './components/shared-component/cities/collectivity-prefix/collectivity-prefix.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShareDataService } from './services/sharedata/share-data.service';
import { FooterComponent } from './components/footer/footer.component';
import { QuotationsListComponent } from './components/user/quotation/quotations-list/quotations-list.component';
import { QuotationCrudComponent } from './components/user/quotation/quotation-crud/quotation-crud.component';
import {MatInputModule} from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { CommonModule, DatePipe } from '@angular/common';
import { DisplayJobAnnoncementComponent } from './components/user/job-announcement/display-job-announcement/display-job-announcement.component';
import { QuotationJobannouncementComponent } from './components/user/quotation/quotation-jobannouncement/quotation-jobannouncement.component';
import { DmDialogComponent } from './components/shared-component/dm-dialog/dm-dialog.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { RegionsComponent } from './components/shared-component/cities/regions/regions.component';
import { FindJobAnnouncementInternalComponent } from './components/shared-component/finders/find-job-announcement-internal/find-job-announcement-internal.component';
import { authInterceptorProviders } from './interceptors/authentication-http-interceptor';
import { NoDataFoundComponent } from './components/shared-component/no-data-found/no-data-found.component';
import { CgvComponent } from './components/cgv/cgv.component';
import { CguComponent } from './components/cgu/cgu.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PdcComponent } from './components/pdc/pdc.component';
import { FaqComponent } from './components/faq/faq.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    CaptchaComponent,
    CreateAccountComponent,
    LoginComponent,
    UserHomeComponent,
    SideNavbarComponent,
    TopNavbarComponent,
    DashboardComponent,
    ProfileComponent,
    BarChartComponent,
    LineChartComponent,
    JobAnnoncementComponent,
    JobAnnouncementListComponent,
    BreadcrumbComponent,
    CompanyComponent,
    MaterialCrudComponent,
    MaterialListComponent,
    FindPilotComponent,
    FindJobAnnouncementComponent,
    CityComponent,
    CityNameComponent,
    CityZipcodeComponent,
    CollectivityPrefixComponent,
    FooterComponent,
    QuotationsListComponent,
    QuotationCrudComponent,
    DisplayJobAnnoncementComponent,
    QuotationJobannouncementComponent,
    DmDialogComponent,
    ForgotPasswordComponent,
    RegionsComponent,
    FindJobAnnouncementInternalComponent,
    NoDataFoundComponent,
    CgvComponent,
    CguComponent,
    PaymentComponent,
    PdcComponent,
    FaqComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularMatFormModule,
    MatButtonToggleModule,
    NgbModule,
    MatInputModule,
    MatFormFieldModule,
    CommonModule
  ],
  providers: [SidenavbarService, ShareDataService, authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { JobAnnouncement } from 'src/app/model/job-announcement';

@Injectable({
  providedIn: 'root'
})
export class JobAnnouncementFormInitService {

   
  constructor() { }

  /**
   * create the gabarit of the form and initialize it 
   * 
   *  * initialize all form controls and all controls validators
   * 
   * @returns 
   * 
   */
  init = (): {[key: string]: any;} => {
    return {
      title: [],
      description: [],
      city: [],
      startingdate: [new Date()], 
      duration: [], 
      tags: [],
      status: [],
      createdate: [], 
      updatedate: [], 
      nbviews: [],
      region:[], 
      department:[],
      zipcode:[]
    }; 
  }

  initForUpdate = (jobAnnouncement : JobAnnouncement ): {[key: string]: any;} => {
    return {
      title: [jobAnnouncement.title],
      description: [jobAnnouncement.description],
      city: [jobAnnouncement.city],
      startingdate: [new Date(jobAnnouncement.startingdate)], 
      duration: [jobAnnouncement.duration], 
      tags: [jobAnnouncement.tags],
      status: [jobAnnouncement.status],
      createdate: [jobAnnouncement.createdate], 
      updatedate: [jobAnnouncement.updatedate], 
      nbviews: [jobAnnouncement.nbviews],
      region:[jobAnnouncement.region], 
      department:[jobAnnouncement.department],
      zipcode:[jobAnnouncement.zipcode]
    }; 
  }
}

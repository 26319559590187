import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StoreService } from '../store/store.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardService {

  
  constructor(  private autenticationService: AuthenticationService,  
                private router: Router ) { }
    
  canActivate (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this.checkUserLogin(next, url);
  }
 
  async checkUserLogin(route: ActivatedRouteSnapshot, url: any)  {
    //let userinfo = await this.autenticationService.getUserInfo();
    let roles = this.autenticationService.getRole (); 
    if (route.data.roles && route.data.roles.indexOf(roles) === -1) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
    //if (userinfo && userinfo !== undefined) {    
      // if (route.data.roles && route.data.roles.indexOf(userinfo.authority) === -1) {
      //   this.router.navigate(['/']);
      //   return false;
      // }
      // return true;
    // }

    // this.router.navigate(['/']);
    // return false;
  }
}

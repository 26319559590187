import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdc',
  templateUrl: './pdc.component.html',
  styleUrls: ['./pdc.component.css']
})
export class PdcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

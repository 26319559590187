import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class DataUtils {

  constructor() {
  }

  public showBooleanBeatify(data: boolean) {
    return data ? 'Oui' : 'Non';
  }

  /**
   * Reduce String depending on his length and keep last word
   * @param value String value
   * @param maxLength Maximum length of the new value
   */
  public displayTitle(value: string, maxLength: number) {
    let result = value;
    // Thesis title is too long for the header
    if (value != null && value !== '' && value.length > maxLength) {
      let words = value.slice(0, maxLength);
      // Delete the full last word and terminate by an empty space
      while (words.slice(words.length - 1, words.length) !== ' ') {
        words = DataUtils.reduceByOneLetter(words);
      }
      // Inform the user that the title was reduced (He can see it in the tooltip on mouseover)
      result = words + '...';
    }
    return result;
  }


  /**
   * Delete last letter from value
   * @param value String Value
   */
  private static reduceByOneLetter(value: string) {
    return value.slice(0, value.length - 1);
  }


}
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LoginFormInitService } from './login-form-init.service';

@Injectable({
  providedIn: 'root'
})
export class LoginFormBuilderService {

  public loginFormGroup : FormGroup;

  constructor(private loginFormBuilder : FormBuilder, 
              public loginFormInitService : LoginFormInitService) { }

  build = () : FormGroup => {
    return this.loginFormBuilder.group (this.loginFormInitService.init());
  }

  buildWithExistingEmail = (email:string) : FormGroup => {
    return this.loginFormBuilder.group (this.loginFormInitService.initWithEmail(email));
  }

 
  public get login () : FormControl {
    return this.loginFormGroup.get('login') as FormControl;
  }

  public get password () : FormControl {
    return this.loginFormGroup.get('password') as FormControl;
  }
}

/**
 * represent collectivity
 */
 export class Collectivity {
    
   name : string;
   zipcode : Array<string>;
   type: string;
   order: number;
   findstr : Array<string>;
   constructor ( name : string, zipcode : Array<string>, order: number, findstr : Array<string>)
   {
        this.name=name; 
        this.zipcode = zipcode;
        this.order = order;
        this.findstr = findstr;
   }
}
  
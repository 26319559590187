/**
 * represent logged user
 */
 export class UserInfo {
    
    /**
     * username
     */
    username : string;
     /**
     * authority
     */
    authority : string;

    constructor(username : string, authority : string) {
        this.username = username;
        this.authority = authority;
    }

    public getAuthority () {
        return this.authority;
    }
    public getUsername () {
        return this.username;
    }
}
  
import { ShareDataService } from "src/app/services/sharedata/share-data.service";
import { BrowserModule } from "@angular/platform-browser";
// Abstract nf : abstract Non functional component
// manage nav bar on F5 press button 

export abstract class BaseComponent {

  message : string = "Internal"
  constructor ( public data: ShareDataService ) {
      this.data.currentMessage.subscribe(message => this.message = message)
      this.hideNavbar();
  }

  ngOnInit(): void {
      this.hideNavbar();
  }
      
  hideNavbar() {
    this.data.changeMessage("Internal")
  }
}

/**
 * represent a login user action to post 
 */
 export class LoginUser {
    
   /*
    * Le image au format base64 
   */
   email: string;
   /*
    * Le image au format base64 
    */
   password: string;
   /**
    * captcha 
    * NFP : non functional prop 
    */
   captcha : string;
   /**
    * NFP
    */
   robot : Boolean;

   constructor(email: string, password: string, captcha: string, robot : Boolean) {
      this.email = email;
      this.password = password;
      this.captcha = captcha;
      this.robot = robot;
   }
}
  
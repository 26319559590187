import { AfterViewInit, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import { SidenavbarService } from 'src/app/services/side-navbar/side-navbar.service';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.css']
})
export class SideNavbarComponent implements OnInit, AfterViewInit {


  isLocked = true;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  cssClassSelected = "gray-background-color";
  cssClassNotSelected = "white-background-color";
  currentCssClass = []
  menu = [];

  constructor ( private sidenavbarService: SidenavbarService,
                private authenticationService : AuthenticationService,
                private data: ShareDataService )  { 

      this.buildMenu (); 
      this.currentCssClass.push(this.cssClassSelected);
      for (let i = 0 ; i < this.menu.length - 1 ; i++) {
        this.currentCssClass.push(this.cssClassNotSelected);
      }
  }


  changeCss (index) {
    
    for (let i = 0 ; i < this.menu.length ; i++) {
      this.currentCssClass[i] = this.cssClassNotSelected; 
    }
    this.currentCssClass[index] = this.cssClassSelected;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.sidenavbarService.setSidenavBar(this);
  }

  mouseenter() {
    if (!this.isLocked) {
      this.isShowing = this.isExpanded;
      this.isExpanded = true;
    }
  }

  mouseleave() {
    if (!this.isLocked) {
      this.isShowing = !this.isExpanded;
      this.isExpanded = false
    }
  }
  closeMobileMenu(){
    var x = document.getElementById("mobileMenuToggle");
        if (x.style.display === "block") {
            x.style.display = "none";
        }
  }
  closeMobileMenuAndLogout(){
    this.closeMobileMenu();
    this.logout();
  }
  logout() {
    this.data.changeMessage("Others")
    this.authenticationService.logout(); 
  };

  public lockUnlockSideNavBar () {
      this.isLocked = !this.isLocked;
      if (this.isLocked)
      {
        this.isShowing = true;
        this.isExpanded = true;
      }
      else 
      {
        this.isShowing = false;
        this.isExpanded = false;
      }
  }


  // buildMenu 

  public buildMenu () { 
    var role = this.authenticationService.getRole (); 

    if (role == 'PILOTE') {
      this.menu =  [
        {
          'link' :'dashboard',
          'title':'Dashboard',
          'iconname':'home'
        },
        {
          'link' :'company',
          'title':'Entreprise',
          'iconname':'group'
        },
        {
          'link' :'list-materials',
          'title':'Mon matériel',
          'iconname':'library_books'
        },
        {
          'link' :'my-material',
          'title':'Ajouter un materiel',
          'iconname':'insert_drive_file'
        },
        {
          'link' :'quotations-list',
          'title':'Mes devis',
          'iconname':'insert_drive_file'
        }
      ]
  }
  else {
    this.menu =  [
      {
        'link' :'dashboard',
        'title':'Dashboard',
        'iconname':'home'
      },
      {
        'link' :'company',
        'title':'Entreprise',
        'iconname':'group'
      },
      {
        'link' :'my-job-announcement',
        'title':'Mes annnonces',
        'iconname':'library_books'
      },
      {
        'link' :'create-job-announcement',
        'title':'Créer une annonce',
        'iconname':'insert_drive_file'
      },
      {
        'link' :'quotation-job-announcement',
        'title':'Mes devis',
        'iconname':'subtitles'
      }
    ]
  }

  }

}



/*

*** Doc ***

https://stackblitz.com/edit/material-sidenav-example?file=app%2Fsidenav-autosize-example.ts,app%2Fsidenav-autosize-example.css

https://stackblitz.com/edit/angular-material-sidenav-generate-nav

*/
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from '../cookie/cookie.service';
import { TokenInfo } from 'src/app/model/token-info';
import {environment} from "../../../environments/environment";
import { AbstractDataService } from './abstract.-data.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TokenDataService extends AbstractDataService {

  
  constructor ( protected http: HttpClient, 
                public cookieService: CookieService,
              ) {
                super();
  }
  
  public refreshToken(tokenInfo: TokenInfo): Observable<any> {
    return this.http.post(this.DRONE_MATCH_CONFIGURATION.EndpointsApi.refreshtoken, tokenInfo);
  }


}

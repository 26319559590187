import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginUser } from 'src/app/model/login-user';
import { UserInfo } from 'src/app/model/user-info';
import { LoadDataService } from 'src/app/services/data/load-data.service';
import { SendDataService } from 'src/app/services/data/send-data.service';
import { UserDataService } from 'src/app/services/data/user-data.service';
import { LoginFormBuilderService } from 'src/app/services/login/login-form-builder.service';
import { StoreService } from 'src/app/services/store/store.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  title = 'Authentification';
  captcha : string = '';
  robot : Boolean = true;
  show : boolean = false;
  
  // is filled if we are redirected from reinitialization password form
  email : string ='';
  
  textError : string = '';
  errorMessageActived:boolean = false; 
  successMessageActived: boolean = false; 
  innerWidth;
  authentication_header_background = 'authentication-header-background';
  
  constructor(  public loginFormBuilderService: LoginFormBuilderService,
                public sendDataService: SendDataService,
                public loadDataService: LoadDataService,
                private userDataService: UserDataService,
                private storeService: StoreService,
                protected route: ActivatedRoute,
                private router: Router
                 ) { }

@HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerWidth = window.innerWidth;
  var screenSize;
  if (this.innerWidth > 850) {
    screenSize = 'big'
  }
  else { 
    screenSize = 'small'
  }
  this.setCssClassForHeader (screenSize); 
}
   
public setCssClassForHeader (screenSize) {
  if ( screenSize === 'big')
  {
      this.authentication_header_background = "authentication-header-background";
  }
  else 
  {
      this.authentication_header_background = "authentication-small-header-background";
  }
}

  ngOnInit(): void {
    this.email = this.route.snapshot.queryParams.email;
    if ( this.email && this.email !== '' ) {  
      this.loginFormBuilderService.loginFormGroup = this.loginFormBuilderService.buildWithExistingEmail(this.email);  
    }
    else {
      this.loginFormBuilderService.loginFormGroup = this.loginFormBuilderService.build();
    }
    this.loadDataService.loadCaptcha ();
  }
  
  async loginEvent () {
    const user = new LoginUser (this.loginFormBuilderService.login.value, 
                                    this.loginFormBuilderService.password.value, 
                                        this.captcha,
                                            this.robot);
    try {
      const tokenInfo = await this.sendDataService.login (user);
      if (tokenInfo != null && tokenInfo != undefined && tokenInfo != '')
      {
        let userinfo : UserInfo = await this.userDataService.getUserInfo(); 
        if (userinfo && userinfo !== undefined) {
          this.router.navigate (['/my-home']);
        }
      }
    }
    catch (error) {
      this.errorMessageActived = true; 
      console.log (error);
      this.textError = error.error.message
        setTimeout(function() {
          this.buttonIsDisabled = false
            setTimeout(function() {
                this.errorMessageActived = false; 
                this.textError = '' 
            }.bind(this), 3000);
        }.bind(this), 1000);
    };
  }  

  getLoginformControl() {
    return this.loginFormBuilderService.login;
  }

  getPasswordformControl() {
    return this.loginFormBuilderService.password;
  }

  getImage() {
    return this.storeService.dataStore.captcha.img;
  }

  getValue($event) {
    this.captcha = $event;
  }

  refreshImage($event) {
    this.loadDataService.loadCaptcha ();
  }

  iamNotARobotUpdate($event) {
    this.robot = $event;
  }

}

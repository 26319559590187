import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import { BaseComponent } from '../../shared-nf-component/base-component';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.css']
})
export class UserHomeComponent extends BaseComponent implements OnInit {

  title = "Bienvenue sur votre espace personnel"
  message:string
  constructor(  private route: ActivatedRoute,
                private router: Router,
                public data: ShareDataService ) { 
                  super(data);
                }
  
  ngOnInit(): void {
    
    // could not show user home component without filling object [dashboard component, profile component ...]
    if (this.router.url === '/my-home') {
        this.router.navigate(['my-home/dashboard']);
    }
  }
}
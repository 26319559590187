import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PilotMaterial } from 'src/app/model/pilot-material';
import { PilotMaterialFormInitService } from './pilot-material-form-init.service';


@Injectable({
  providedIn: 'root'
})
export class PilotMaterialFormBuilderService {

  public pilotMaterialFormGroup : FormGroup;

  constructor( private pilotMaterialFormBuilder : FormBuilder, 
              public pilotMaterialFormInitService : PilotMaterialFormInitService ) { }

  build = () : FormGroup => {
    return this.pilotMaterialFormBuilder.group (this.pilotMaterialFormInitService.init());
  }

  buildForUpdate = (pilotMaterial : PilotMaterial) : FormGroup => {
    return this.pilotMaterialFormBuilder.group (this.pilotMaterialFormInitService.initForUpdate(pilotMaterial),{validators: [this.conditionallyRequiredValidator]});
  }

  conditionallyRequiredValidator(formGroup: FormGroup) {
    if (formGroup.value.type=='drone') {
      return Validators.required(formGroup.get('classe')) ? {
        classeFieldConditionallyRequired: true,
      } : Validators.required(formGroup.get('securite')) ? {
        securiteFieldConditionallyRequired: true,
      } : null;
    }else if(formGroup.value.type=='camera'){
      return Validators.required(formGroup.get('definition')) ? {
        definitionFieldConditionallyRequired: true,
      } : Validators.required(formGroup.get('thermique')) ? {
        thermiqueFieldConditionallyRequired: true,
      } : null;
    }else return Validators.required(formGroup.get('type'))?{typeFieldConditionallyRequired:true}:null;
  }

  // public get designation () : FormControl {
  //   return this.pilotMaterialFormGroup.get('designation') as FormControl;
  // }

  // public get description () : FormControl {
  //   return this.pilotMaterialFormGroup.get('description') as FormControl;
  // }

  // public get typeMaterial () : FormControl {
  //   return this.pilotMaterialFormGroup.get('typeMaterial') as FormControl;
  // }

  // public get approval () : FormControl {
  //   return this.pilotMaterialFormGroup.get('approval') as FormControl;
  // }

  // public get hdCam () : FormControl {
  //   return this.pilotMaterialFormGroup.get('hdCam') as FormControl;
  // }

  // public get thermalCam () : FormControl {
  //   return this.pilotMaterialFormGroup.get('thermalCam') as FormControl;
  // }

  // public get obstacleDetection () : FormControl {
  //   return this.pilotMaterialFormGroup.get('obstacleDetection') as FormControl;
  // }

  public get type () : FormControl {
    return this.pilotMaterialFormGroup.get('type') as FormControl;
  }
  public get model () : FormControl {
    return this.pilotMaterialFormGroup.get('model') as FormControl;
  }
  public get definition () : FormControl {
    return this.pilotMaterialFormGroup.get('definition') as FormControl;
  }
  public get thermique () : FormControl {
    return this.pilotMaterialFormGroup.get('thermique') as FormControl;
  }
  public get classe () : FormControl {
    return this.pilotMaterialFormGroup.get('classe') as FormControl;
  }
  public get securite () : FormControl {
    return this.pilotMaterialFormGroup.get('securite') as FormControl;
  }

  public get materialImage () : FormControl {
    return this.pilotMaterialFormGroup.get('materialImage') as FormControl;
  }

  public setMaterialImageValue (image : File) {
    this.pilotMaterialFormGroup.patchValue({
      materialImage: image 
    });
  }

  public get createdate () : FormControl {
    return this.pilotMaterialFormGroup.get('createdate') as FormControl;
  }

  public get updatedate () : FormControl {
    return this.pilotMaterialFormGroup.get('updatedate') as FormControl;
  }
}
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/shared-nf-component/base-component';
import { JobAnnouncement } from 'src/app/model/job-announcement';
import { JobAnnouncementDataService } from 'src/app/services/data/job-announcement-data.service';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { DmDialogComponent } from 'src/app/components/shared-component/dm-dialog/dm-dialog.component';

@Component({
  selector: 'app-job-announcement-list',
  templateUrl: './job-announcement-list.component.html',
  styleUrls: ['./job-announcement-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
})
export class JobAnnouncementListComponent extends BaseComponent implements OnInit, AfterViewInit  {

  @Input() showBreadCrumb: boolean = true;
  jobAnnouncementDataSource = new MatTableDataSource<JobAnnouncement>();
  @ViewChild("jobAnnouncementPaginator") paginator: MatPaginator;
  jobAnnouncementdisplayedColumns: string[] = jobAnnouncementColumns.map((col) => col.key);
  columnsToDisplayWithExpand = [...this.jobAnnouncementdisplayedColumns, 'expand'];
  jobAnnouncementcolumnsSchema: any = jobAnnouncementColumns;
  expandedElement: JobAnnouncement | null;

  // displayedColumns: string[] = jobAnnouncementColumns.map((col) => col.key);
  // columnsSchema: any = jobAnnouncementColumns;
  // dataSource = new MatTableDataSource<JobAnnouncement>();
  valid: any = {};

  //@ViewChild(MatPaginator) paginator: MatPaginator;

  successMessageDelete = false;
  errorMessageDelete = false;
  message:string
  jobAnnouncementList; 
  panelOpenState = false;

  constructor (  public dialog: MatDialog, 
                private jobAnnouncementService: JobAnnouncementDataService, 
                private router : Router,
                protected route: ActivatedRoute,               
                public data: ShareDataService ) { 
                    super(data)
                }

  ngOnInit() {
    this.jobAnnouncementService.getJobAnnouncements().subscribe((res: any) => {
      res.sort((first, second) => {
        if (first.startingdate == null || second.startingdate == null) {
          return -1;
        }
        return new Date(second.startingdate).getTime() - new Date(first.startingdate).getTime();
      });
      this.jobAnnouncementList=res;
      this.jobAnnouncementDataSource.data = res;
      this.jobAnnouncementDataSource.paginator = this.paginator;
    });
  }

  ngAfterViewInit() {
    this.jobAnnouncementDataSource.paginator = this.paginator;
  }

  goToJobAnnouncement(row) {
    this.router.navigate(['my-home/modify-job-announcement', row.id]);
  }

  openDialog (id: any, ref: any): void {
      let dialogRef = this.dialog.open(DmDialogComponent, {
      width: '250px', 
      data : {
          message : '- Etes-vous sur de vouloir supprimer cette annonce :' + ref
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'validate' ) {
        this.removeRow(id);
      }
      if (result === 'cancel') {
        console.log('The action was canceled');
      }
    });
}



  removeRow(id: string) {
    this.jobAnnouncementService.deleteJobAnnouncement(Number(id)).subscribe((result) => {
      if ( result === 1 ) {
          this.jobAnnouncementDataSource.data = this.jobAnnouncementDataSource.data.filter(
            (u: JobAnnouncement) => u.id !== id);
            this.successMessageDelete = true;
            setTimeout(function() {
            this.successMessageDelete = false;
          }.bind(this), 3000);
      }
      else {
        this.errorMessageDelete = true;
        setTimeout(function() {
          this.errorMessageDelete = false;
        }.bind(this), 3000);
      }
    });
  }
}

export const jobAnnouncementColumns = [
  {
    key: 'annonceref',
    type: 'text',
    label: 'Ref',
    required: true,
  },
  {
    key: 'title',
    type: 'text',
    label: 'Titre',
    required: true,
  },
  {
    key: 'city',
    type: 'text',
    label: 'Ville',
  },
  {
    key: 'startingdate',
    type: 'date',
    label: 'Date Début',
  },
  {
    key: 'status',
    type: 'badge',
    label: 'Statut',
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: '',
  }
];

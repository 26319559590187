import { Component, OnInit } from '@angular/core';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import { BaseComponent } from '../../shared-nf-component/base-component';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends BaseComponent implements OnInit {

  entreprise : boolean = false;
  message:string;




  constructor( public data: ShareDataService,  
                public authenticationService : AuthenticationService) { 
      super(data)
    const role = authenticationService.getRole();
    this.entreprise = role == 'ENTREPRISE' ? true : false
  }
}

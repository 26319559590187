import { Collectivity } from 'src/app/model/collectivity';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoadDataService } from 'src/app/services/data/load-data.service';
import { SendDataService } from 'src/app/services/data/send-data.service';
import { StoreService } from 'src/app/services/store/store.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-collectivity-prefix',
  templateUrl: './collectivity-prefix.component.html',
  styleUrls: ['./collectivity-prefix.component.css']
})
export class CollectivityPrefixComponent implements OnInit {

  collectivities : Collectivity [] = [];
  tmpcollectivities : Collectivity [] = [];
 
   myControl = new FormControl('');
   filteredOptions: Observable<Collectivity[]>;
   
   @Output ()
   onSelectCollectivity : EventEmitter<Collectivity> = new EventEmitter<Collectivity>();

 
  constructor( public sendDataService: SendDataService,
                public loadDataService: LoadDataService,
                public storeService: StoreService ) {
  }
 
   ngOnInit(): void {
      this.myControl.valueChanges.subscribe( (findStr) => {
            this.autoCompleteCity (findStr);  
            if (findStr ==='') {
              let c = []; c[0] = ''; 
              this.onSelectCollectivity.emit (new Collectivity('',c,0,c));
            } 
        }
      );
   }

   autoCompleteCity (findStr : any) {
      if (typeof findStr === 'string') {
        findStr = findStr.toLowerCase();
        if (findStr.length > 1) {
          if (findStr.length < 3) {
              this.loadDataService.loadCollectivitiesByPrefix (findStr).then ((collectivities) => {
                this.collectivities=collectivities;
                this.tmpcollectivities=collectivities;
              });
          }
          else {
              this.collectivities = this.tmpcollectivities.filter(collectivity =>
                collectivity.findstr.join(",").toLowerCase().includes(findStr)
              );
          }
        }
      }
   }

   display (collectivity : any) {
      return collectivity.name;
   }
 
   filterCollectivities (event) {
     const selectedCollectivity = event.source.value as Collectivity; 
     this.onSelectCollectivity.emit (selectedCollectivity);
   }
}

/**
 * represent a pilot material 
 */
 export class PilotMaterial {
    
   /*
    * identifier 
   */
   id: string;         
                  
   /*
    * type : Drone or Camera
    */
   type : string;

   /**
    * brand and model
    */
   model : string;

   /**
    * for a camera, definition in Meagapixel
    */
   definition : string;

   /**
    * for a camera, is the camera thermical ? 
    */
   thermique : string;

   /**
    * for a drone, its class c0a1 c1a1 c2a2
    */
   classe : string;

   /**
    * for a drone, does it own security material?
    */
   securite : string;

   
   /**
    * image of the material
    */
    materialImage: File
    byteMaterialImage : any;
    
    /**
    * creation date of the material
    */
   createdate : Date;

    /**
    * update date of the material
    */
   updatedate : Date;





 



//OLD PROPERTIES
/*
designation: string, description : string, typeMaterial : string,
                 approval : string, hd_cam : string, thermal_cam : string,
                 obstacleDetection : string
                 */




   constructor (  id : string,
                  type : string, 
                  model : string, 
                  definition : string,
                  thermique : string,
                  classe : string,
                  securite : string,
                  image: File, 
                  createdate : Date, 
                  updatedate : Date)
   {
      this.id=id; 
      this.type=type; 
      this.model=model; 
      this.definition=definition; 
      this.thermique=thermique; 
      this.classe=classe; 
      this.securite=securite;
      this.materialImage=image;
      this.createdate=createdate;
      this.updatedate=updatedate;
   }

   toFormData () : FormData {
      
      const formData = new FormData();
      formData.append("id", this.id); 
      formData.append("type", this.type);
      formData.append("model", this.model);
      formData.append("definition", this.definition);
      formData.append("thermique", this.thermique);
      formData.append("classe", this.classe);
      formData.append("securite", this.securite);
      formData.append("materialImage", this.materialImage);
      formData.append("createdate", this.createdate.toString());
      formData.append("updatedate", this.updatedate.toString());
      
      return formData;
   }

   
}
  
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReinitializePasswordFormInitService} from './reinitialize-password-init.service';


@Injectable({
  providedIn: 'root'
})
export class ReinitializePasswordFormBuilderService {

  public reinitializePasswordFormGroup : FormGroup;

  constructor(private reinitializePasswordFormBuilder : FormBuilder, 
              public reinitializePasswordFormInitService : ReinitializePasswordFormInitService) { }

  build = () : FormGroup => {
    return this.reinitializePasswordFormBuilder.group (this.reinitializePasswordFormInitService.init());
  }

  public get email () : FormControl {
    return this.reinitializePasswordFormGroup.get('email') as FormControl;
  }
}

import { Injectable } from '@angular/core';
import { JobAnnouncement } from 'src/app/model/job-announcement';
import { Quotation } from 'src/app/model/quotation';
import { QuotationLight } from 'src/app/model/quotation-light';

@Injectable({
  providedIn: 'root'
})
export class QuotationFormInitService {

   
  constructor() { }

  /**
   * create the gabarit of the form and initialize it 
   * 
   *  * initialize all form controls and all controls validators
   * 
   * @returns 
   * 
   */
  init = (): {[key: string]: any;} => {
    return {
      idJobAnnouncement: [],
      price: [],
      availability: [],
    }; 
  }

  initForUpdate = (quotation : QuotationLight ): {[key: string]: any;} => {
    return {
      idJobAnnouncement: [quotation.idJobAnnouncement],
      price: [quotation.price],
      availability: [new Date(quotation.availability)],
    }; 
  }

  // initForCreate = (jobAnnouncement : JobAnnouncement): {[key: string]: any;} => {
  //   return {
  //     idJobAnnouncement: [jobAnnouncement.id],
  //     price: [],
  //     availability: [],
  //   }; 
  // }

  initForCreate = (jobAnnouncementId): {[key: string]: any;} => {
    return {
      idJobAnnouncement: [jobAnnouncementId],
      price: [0],
      availability: [new Date()],
    }; 
  }


  initForEdit = (quotation : Quotation ): {[key: string]: any;} => {
    return {
      id: [quotation.id],
      jobAnnouncement: [quotation.jobAnnouncement],
      pilote: [quotation.pilote],
      price: [quotation.price],
      availability: [new Date(quotation.availability)],
      status: [quotation.status]
    }; 
  }
}

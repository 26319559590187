import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ChangePasswordFormInitService } from './change-password-init.service';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordFormBuilderService {

  public changePasswordFormGroup : FormGroup;

  constructor(private changePasswordFormBuilder : FormBuilder, 
              public changePasswordFormInitService : ChangePasswordFormInitService) { }

  build = () : FormGroup => {
    return this.changePasswordFormBuilder.group (this.changePasswordFormInitService.init());
  }
  public get currentPassword () : FormControl {
    return this.changePasswordFormGroup.get('currentPassword') as FormControl;
  }

  public get newPassword () : FormControl {
    return this.changePasswordFormGroup.get('newPassword') as FormControl;
  }
  public get newPasswordRetaped () : FormControl {
    return this.changePasswordFormGroup.get('newPasswordRetaped') as FormControl;
  }
}

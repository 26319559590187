import { Component, HostListener, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { DroneMatchAccount } from 'src/app/model/drone-match-account';
import { CreateAccountFormBuilderService } from 'src/app/services/create-account/create-account-form-builder.service';
import { CreateAccountInitService } from 'src/app/services/create-account/create-account-init.service';
import { LoadDataService } from 'src/app/services/data/load-data.service';
import { SendDataService } from 'src/app/services/data/send-data.service';
import { StoreService } from 'src/app/services/store/store.service';



@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {

  create_account_background = "create-account-pilote-header-background";

  title : string;
  type : string;
  captchaError : boolean = false;
  /** change view to the welcome message : account should already created */
  created : Boolean = false;
  captcha : string = '';
  robot : Boolean = true;
  show : boolean = false;
  buttonIsDisabled : boolean = false
  emailValidationError: boolean = false;
  textError : string = '';
  errorMessageActived:boolean = false; 
  successMessageActived: boolean = false; 
  innerWidth;

  constructor(public createAccountInitService : CreateAccountInitService,
              public createAccountFormBuilderService : CreateAccountFormBuilderService,
              public sendDataService : SendDataService,
              private router : Router,
              public loadDataService : LoadDataService,
              public storeService: StoreService ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    var screenSize;
    if (this.innerWidth > 850) {
      screenSize = 'big'
    }
    else { 
      screenSize = 'small'
    }
    this.setCssClassForHeader (screenSize); 
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;    
    var screenSize;
    if (this.innerWidth > 850)
      screenSize = 'big'
    else 
      screenSize = 'small'

    this.setCssClassForHeader (screenSize); 

    this.createAccountFormBuilderService.createAccount = this.createAccountFormBuilderService.build();

    this.handlEmailEvent();
    this.loadDataService.loadCaptcha ();
  }

  
  public setCssClassForHeader (screenSize) {
    if (this.router.url == '/create-account-pilote') {
      this.title='Pilote de drone : Créer un compte';
      this.type = 'Pilote';
          if ( screenSize === 'big')
          {
              this.create_account_background = "create-account-pilote-header-background";
          }
          else 
          {
              this.create_account_background = "create-account-pilote-small-header-background";
          }
      }
      else {
          this.title='Entreprise : Créer un compte';
          this.type = 'Entreprise';
          if ( screenSize === 'big')
          {
              this.create_account_background = "create-account-client-header-background";
          }
          else 
          {
              this.create_account_background = "create-account-client-small-header-background";
          }
      }
  }

  createAccountEvent () {
    this.buttonIsDisabled = true
    const account = new DroneMatchAccount (this.type, this.createAccountFormBuilderService.email.value, this.captcha, this.robot);
    this.sendDataService.createAccount (account)
      .then((result) => {
        if (result === 'CREATED') {
          this.created = true;
        }
      },
      (error) => {
        this.errorMessageActived = true; 
        this.textError = error.error.message
           setTimeout(function() {
            this.buttonIsDisabled = false
            setTimeout(function() {
              this.errorMessageActived = false; 
              this.textError = '' 
            }.bind(this), 3000);
          }.bind(this), 1000);
        this.loadDataService.loadCaptcha ();
        this.captcha = '';
        this.robot=true;
      }
    );
  }  

  handlEmailEvent = () => {
    this.createAccountFormBuilderService.email.valueChanges.subscribe ((email)=> {
        console.log (email);
     }); 
  }

  getEmailformControl() {
      return this.createAccountFormBuilderService.email;
  }

  getImage() {
    return this.storeService.dataStore.captcha.img;
  }

  getValue($event) {
    this.captcha = $event;
  }

  refreshImage($event) {
    this.loadDataService.loadCaptcha ();
  }

  iamNotARobotUpdate($event) {
    this.robot = $event;
  }

}

/**
 * represent a job announcement 
 */
 export class JobAnnouncement {
    
   /*
    * identifier 
   */
   id: string;
   /*
    * title of the job announcement
    */
   title: string;
   /**
    * description
    */
   description : string;
   /**
    * city
    */
   city : string;

   /**
    * region
    */
   region : string;

   /**
    * department
    */
   department : string;

   /**
    * zipcode
    */
   zipcode : string;

   /**
    * starting date mission
    */
    startingdate : Date;

   /**
    * duration
    */
    duration : string;

   /**
    * key words
    */
    tags : string;

   /**
    * status
    */
    status : string;

   /**
    * creation date of tha job announcement
    */
    createdate : Date;

    /**
    * upodate date
    */
   updatedate : Date;

   /**
    * nomber of view
    */
    nbviews : string;

   showQuotation : true; 
   constructor ( id: string, title: string, description : string, city : string,
      region : string,department : string,zipcode : string,
                 startingdate : Date, duration : string, tags : string,
                 status : string, createdate : Date, updatedate : Date, nbviews : string)
   {
      this.id=id; 
      this.title=title;
      this.description=description;
      this.city=city;
      this.region=region;
      this.department=department;
      this.zipcode=zipcode;
      this.startingdate=startingdate;
      this.duration=duration;
      this.tags=tags;
      this.status=status;
      this.createdate=createdate;
      this.updatedate=updatedate;
      this.nbviews=nbviews;
   }

   
}
  
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JobAnnouncement } from 'src/app/model/job-announcement';
import { CookieService } from 'src/app/services/cookie/cookie.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { AbstractDataService } from './abstract.-data.service';

@Injectable({
  providedIn: 'root',
})
export class JobAnnouncementDataService extends AbstractDataService {

  private serviceUrl = this.DRONE_MATCH_CONFIGURATION.EndpointsApi.jobannouncement;
  private servicePiloteUrl = this.DRONE_MATCH_CONFIGURATION.EndpointsApi.pilotejobannouncement;
  private serviceFinderUrl = this.DRONE_MATCH_CONFIGURATION.EndpointsApi.globaljobannouncementfinder;
  private pilotejobannouncementfinderUrl = this.DRONE_MATCH_CONFIGURATION.EndpointsApi.pilotejobannouncementfinder;

  constructor(  private http: HttpClient,  
                public cookieService: CookieService,
                public authenticationService: AuthenticationService 
                 ) {
    super();
  }

  public getPiloteJobAnnouncementByStatus (status: string): Observable<JobAnnouncement[]> {

    return this.http.get(this.serviceUrl+`/status/${status}`)
      .pipe<JobAnnouncement[]>(map((data: any) => data));
  }
  
  // Find job announcements 
  public findJobAnnouncements(tags, region, department, zipcode, city, findByFields): Observable<JobAnnouncement[]> {
    const params = new HttpParams({fromObject: {tags: tags, 
      region: region, 
      department: department, 
      zipcode: zipcode, 
      cityName: city, 
      findByFields: findByFields}});

    return this.http
      .get(this.serviceFinderUrl, {params: params })
        .pipe<JobAnnouncement[]>(map((data: any) => data));
  }

  
  
  // Find job announcements 
  public findJobAnnouncementsForPilote(tags, region, department, zipcode, city, findByFields): Observable<JobAnnouncement[]> {
    const params = new HttpParams({fromObject: {tags: tags, 
      region: region, 
      department: department, 
      zipcode: zipcode, 
      cityName: city, 
      findByFields: findByFields}});

    return this.http
      .get(this.pilotejobannouncementfinderUrl, {params: params })
        .pipe<JobAnnouncement[]>(map((data: any) => data));
  }

  
  public getJobAnnouncements(): Observable<JobAnnouncement[]> {
    return this.http
      .get(this.serviceUrl)
      .pipe<JobAnnouncement[]>(map((data: any) => data));
  }

  public getJobAnnouncementById(id: number): Promise<any> {

    return this.http.get(this.serviceUrl+`/${id}`)
      .toPromise()
      .then((jobAnnouncement) => {
        return jobAnnouncement;
      });
  }
  public getPiloteJobAnnouncementById(id: number): Promise<any> {
    
    return this.http.get(this.servicePiloteUrl+`/${id}`)
      .toPromise()
      .then((jobAnnouncement) => {
        return jobAnnouncement;
      });
  }
  


  public deleteJobAnnouncement(id: number): Observable<number> {
    return this.http.delete<number>(
        `${this.serviceUrl}/${id}`)
        .pipe<number>(map((result: any) => result));
  }

  
  public createJobAnnouncement (jobAnnouncement: JobAnnouncement): Promise<any> {

    return this.http.post ( 
              this.serviceUrl, 
              jobAnnouncement)
      .toPromise ()
      .then (
        result => {
          return result;
        }
      );
  }
}

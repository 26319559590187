import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CreateAccountInitService {

  
  constructor() { }

  /**
   * create the gabarit of the form and initialize it 
   * 
   *  * initialize all form controls and all controls validators
   * 
   * @returns 
   * 
   */
  init = (): {[key: string]: any;} => {
    return {
      emailFormControl : ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]]
    }; 
  }
}

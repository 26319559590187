import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../shared-nf-component/base-component';
import { ActivatedRoute, Router } from '@angular/router';
import { ShareDataService } from '../../services/sharedata/share-data.service';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent extends BaseComponent implements OnInit {

  id :any;
  price : any;
  
  constructor (
    protected route: ActivatedRoute,
    public router: Router,               
    public data: ShareDataService ) { 
    super(data)
}

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.id = params.id;
      this.price = params.price;
      
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CityNameComponent } from 'src/app/components/shared-component/cities/city-name/city-name.component';
import { BaseComponent } from 'src/app/components/shared-nf-component/base-component';
import { CityDto } from 'src/app/model/city-dto';
import { JobAnnouncement } from 'src/app/model/job-announcement';
import { JobAnnouncementDataService } from 'src/app/services/data/job-announcement-data.service';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import { JobAnnouncementFormBuilderService } from 'src/app/services/user-services/job-announcement/job-announcement-form-builder.service';

@Component({
  selector: 'app-job-announcement',
  templateUrl: './job-announcement.component.html',
  styleUrls: ['./job-announcement.component.css']
})
export class JobAnnoncementComponent extends BaseComponent implements OnInit {

  id :number;
  jobAnnouncement : JobAnnouncement;
  buttonText = "Créer annonce"; 
  buttonColor = "primary";
  successMessageEdited = false;
  errorMessageEdited = false;
  message:string
  @ViewChild('citybyname') cityByName!: CityNameComponent;
  cityName;
  breadcrumbTitle = "Créer une annonce" 
  constructor ( public jobAnnouncementFormBuilderService : JobAnnouncementFormBuilderService,
                public jobAnnouncementDataService: JobAnnouncementDataService,
                protected route: ActivatedRoute,
                public router: Router,               
                public data: ShareDataService ) { 
    super(data)

  }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    if ( this.id !== 0 ) {  
      this.buttonText = "Mettre à jour";
      // TODO  to change with update color style
      this.buttonColor = "primary";
      this.breadcrumbTitle = "Mise à jour d'une annonce"
      this.jobAnnouncementDataService.getJobAnnouncementById(this.id).then
          ((result) => {this.jobAnnouncement = result;
            this.jobAnnouncementFormBuilderService.jobAnnouncementFormGroup = this.jobAnnouncementFormBuilderService.buildForUpdate(this.jobAnnouncement);
            this.cityName = this.jobAnnouncement.city; 
          });
    }
    else {
      this.jobAnnouncementFormBuilderService.jobAnnouncementFormGroup = this.jobAnnouncementFormBuilderService.build();
    }
  }

  onSelectCity(event) {
    var citydto = event as CityDto;
    this.jobAnnouncementFormBuilderService.city.patchValue(citydto.city.nom);      
    this.jobAnnouncementFormBuilderService.region.patchValue(citydto.city.codeRegion);      
    this.jobAnnouncementFormBuilderService.department.patchValue(citydto.city.codeDepartement);      
    this.jobAnnouncementFormBuilderService.zipcode.patchValue(citydto.city.codesPostaux[0] );      
  }
  public createOrUpdateJobAnnouncement () {

        if (this.id == 0) {
          this.id = null;
        }
        const jobAnnouncement = new JobAnnouncement (''+this.id,
        this.jobAnnouncementFormBuilderService.title.value, 
        this.jobAnnouncementFormBuilderService.description.value, 
        this.jobAnnouncementFormBuilderService.city.value, 
        this.jobAnnouncementFormBuilderService.region.value, 
        this.jobAnnouncementFormBuilderService.department.value, 
        this.jobAnnouncementFormBuilderService.zipcode.value, 
        new Date(this.jobAnnouncementFormBuilderService.startingdate.value), 
        this.jobAnnouncementFormBuilderService.duration.value, 
        this.jobAnnouncementFormBuilderService.tags.value, 
        this.jobAnnouncementFormBuilderService.status.value, 
        new Date(this.jobAnnouncementFormBuilderService.createdate.value), 
        new Date(this.jobAnnouncementFormBuilderService.updatedate.value), 
        this.jobAnnouncementFormBuilderService.nbviews.value);
        console.log (jobAnnouncement);
        this.jobAnnouncementDataService.createJobAnnouncement (jobAnnouncement)
        .then ((result) => {
            this.successMessageEdited = true;
            setTimeout(function() {
              this.successMessageEdited = false;
              this.router.navigate([`/my-home/modify-job-announcement/${result.id}`]);
            }.bind(this), 3000);
          }
        )
        .catch(err => {
            this.errorMessageEdited = true;
            setTimeout(function() {
              this.errorMessageEdited = false;
            }.bind(this), 3000);
          }
        );
  }

  ifHaveToDisableButton() : boolean {
    if ( this.jobAnnouncement!= undefined 
          && this.jobAnnouncement.status!= undefined 
          &&this.id !== 0 ) {  
      return  this.jobAnnouncement.status==='PUBLISHED' ||
              this.jobAnnouncement.status==='CANCELED' ||
              this.jobAnnouncement.status==='ASSIGNED' ||
              this.jobAnnouncement.status==='FINISHED';
    }
    else { 
      return false;
    }
  }

  public getTitleFormControl() {
    return this.jobAnnouncementFormBuilderService.title;
  }

  public getDescriptionFormControl () { 
    return this.jobAnnouncementFormBuilderService.description;
  }

  public getCityFormControl () {
    return this.jobAnnouncementFormBuilderService.city;
  }

  public getStartingdateFormControl () {
    return this.jobAnnouncementFormBuilderService.startingdate;
  }

  public getDurationFormControl () {
    return this.jobAnnouncementFormBuilderService.duration;
  }

  public getTagsFormControl () {
    return this.jobAnnouncementFormBuilderService.tags;
  }

  public getStatusFormControl () {
    return this.jobAnnouncementFormBuilderService.status;
  }

  public getCreatedateFormControl () {
    return this.jobAnnouncementFormBuilderService.createdate;
  }

  public getUpdatedateFormControl () {
    return this.jobAnnouncementFormBuilderService.updatedate;
  }

  public getNbviewsFormControl () {
    return this.jobAnnouncementFormBuilderService.nbviews;
  }

}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/shared-nf-component/base-component';
import { PilotMaterial } from 'src/app/model/pilot-material';
import { PilotMaterialDataService } from 'src/app/services/data/pilot-material-data.service';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';

@Component({
  selector: 'app-material-list',
  templateUrl: './material-list.component.html',
  styleUrls: ['./material-list.component.css']
})
export class MaterialListComponent extends BaseComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = materialColumns.map((col) => col.key);
  columnsSchema: any = materialColumns;
  dataSource = new MatTableDataSource<PilotMaterial>();
  valid: any = {};

  @ViewChild(MatPaginator) paginator: MatPaginator;

  successMessageDelete = false;
  errorMessageDelete = false;
  message:string
  materialList;
  constructor( private pilotMaterialDataService: PilotMaterialDataService, 
               private router : Router,
               protected route: ActivatedRoute, 
               public data: ShareDataService ) { 
                super(data)
              }

  ngOnInit() {
    this.pilotMaterialDataService.getMaterials().subscribe((res: any) => {
      res.sort((first, second) => {
        if (first.updatedate == null && second.updatedate == null) {
          return new Date(second.createdate).getTime() - new Date(first.createdate).getTime();
        } else if (first.updatedate == null && second.updatedate != null) {
          return new Date(second.updatedate).getTime() - new Date(first.createdate).getTime();
        } else if (first.updatedate != null && second.updatedate == null) {
          return new Date(second.createdate).getTime() - new Date(first.updatedate).getTime();
        } else {                     
          return new Date(second.updatedate).getTime() - new Date(first.updatedate).getTime();
        }
      });
      this.materialList = res;
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
    });
 
 
  }

  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  goToMaterial(row) {
    this.router.navigate(['my-home/my-material', row.id]);
  }


  removeRow(id: string) {
    this.pilotMaterialDataService.deleteMaterial (Number(id)).subscribe((result) => {
      if ( result === 1 ) {
          this.dataSource.data = this.dataSource.data.filter (
            (u: PilotMaterial) => u.id !== id);
            this.successMessageDelete = true;
            setTimeout(function() {
            this.successMessageDelete = false;
          }.bind(this), 3000);
      }
      else {
        this.errorMessageDelete = true;
        setTimeout(function() {
          this.errorMessageDelete = false;
        }.bind(this), 3000);
      }
    });
  }
}

export const materialColumns = [
  {
    key: 'type',
    type: 'text',
    label: 'type',
    required: true,
  },
  {
    key: 'model',
    type: 'text',
    label: 'Modele',
  },
  {
    key: 'definition',
    type: 'text',
    label: 'Definition Cam',
  },
  {
    key: 'classe',
    type: 'text',
    label: 'Classe de drone',
  },
  {
    key: 'securite',
    type: 'text',
    label: 'Equipement de sécurité',
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: '',
  },
];

import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { JobAnnouncement } from 'src/app/model/job-announcement';
import { JobAnnouncementFormInitService } from './job-announcement-form-init.service';

@Injectable({
  providedIn: 'root'
})
export class JobAnnouncementFormBuilderService {
  public jobAnnouncementFormGroup : FormGroup;

  constructor( private jobAnnouncementFormBuilder : FormBuilder, 
              public jobAnnouncementFormInitService : JobAnnouncementFormInitService ) { }

  build = () : FormGroup => {
    return this.jobAnnouncementFormBuilder.group (this.jobAnnouncementFormInitService.init());
  }

  buildForUpdate = (jobAnnouncement : JobAnnouncement) : FormGroup => {
    return this.jobAnnouncementFormBuilder.group (this.jobAnnouncementFormInitService.initForUpdate(jobAnnouncement));
  }

  public get title () : FormControl {
    return this.jobAnnouncementFormGroup.get('title') as FormControl;
  }

  public get description () : FormControl {
    return this.jobAnnouncementFormGroup.get('description') as FormControl;
  }

  public get city () : FormControl {
    return this.jobAnnouncementFormGroup.get('city') as FormControl;
  }

  public get region () : FormControl {
    return this.jobAnnouncementFormGroup.get('region') as FormControl;
  }

  public get department () : FormControl {
    return this.jobAnnouncementFormGroup.get('department') as FormControl;
  }

  public get zipcode () : FormControl {
    return this.jobAnnouncementFormGroup.get('zipcode') as FormControl;
  }

  public get startingdate () : FormControl {
    return this.jobAnnouncementFormGroup.get('startingdate') as FormControl;
  }

  public get duration () : FormControl {
    return this.jobAnnouncementFormGroup.get('duration') as FormControl;
  }

  public get tags () : FormControl {
    return this.jobAnnouncementFormGroup.get('tags') as FormControl;
  }

  public get status () : FormControl {
    return this.jobAnnouncementFormGroup.get('status') as FormControl;
  }

  public get createdate () : FormControl {
    return this.jobAnnouncementFormGroup.get('createdate') as FormControl;
  }

  public get updatedate () : FormControl {
    return this.jobAnnouncementFormGroup.get('updatedate') as FormControl;
  }

  public get nbviews () : FormControl {
    return this.jobAnnouncementFormGroup.get('nbviews') as FormControl;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DmUser } from 'src/app/model/dmuser';
import { LoadDataService } from 'src/app/services/data/load-data.service';
import { SendDataService } from 'src/app/services/data/send-data.service';
import { StoreService } from 'src/app/services/store/store.service';
import { ProfileFormBuilderService } from 'src/app/services/user-services/profile/profile-form-builder.service';
import { City } from 'src/app/model/city';
import { CityNameComponent } from '../../shared-component/cities/city-name/city-name.component';
import { CityZipcodeComponent } from '../../shared-component/cities/city-zipcode/city-zipcode.component';
import { CityDto } from 'src/app/model/city-dto';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import { BaseComponent } from '../../shared-nf-component/base-component';
import { ChangePasswordFormBuilderService } from 'src/app/services/change-password/change-password-form-builder.service';
import { DroneMatchUpdatePassword } from 'src/app/model/drone-match-update-password';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent extends BaseComponent implements OnInit {

  
  buttonText = "Mettre à jour"; 
  buttonColor = "primary";
  successMessageEdited = false;
  errorMessageEdited = false;
  cityName = ''; 
  zipCode = ''; 
  @ViewChild('citybyname') cityByName!: CityNameComponent;
  @ViewChild('citybyzipcode') cityByZipCode!: CityZipcodeComponent;
  message:string
  
  buttonIsDisabled : boolean = false;
  errorMessageActived:boolean = false; 
  successMessageActived: boolean = false; 
  textError : string = '';

  constructor ( public profileFormBuilderService : ProfileFormBuilderService,
                public changePasswordFormBuilderService : ChangePasswordFormBuilderService,
                public authenticationService : AuthenticationService,
                public sendDataService: SendDataService,
                public loadDataService: LoadDataService,
                public storeService: StoreService,
                protected route: ActivatedRoute,
                public router: Router,
                public data: ShareDataService ) { 
                  super(data)
  }

  ngOnInit(): void {
      this.buttonIsDisabled = true;
      this.loadDataService.getCurrentUser().then ((user) => {
          if (user != null) {
            this.cityName = user.city; 
            this.zipCode = user.zipcode;
            this.profileFormBuilderService.profileFormGroup = this.profileFormBuilderService.buildForUpdate(user);
          }
      });
      this.changePasswordFormBuilderService.changePasswordFormGroup = this.changePasswordFormBuilderService.build();
    }

    onSelectCity(event) {
      var citydto = event as CityDto;
      this.profileFormBuilderService.city.patchValue(citydto.city.nom);  
      this.profileFormBuilderService.zipcode.patchValue (citydto.city.codesPostaux[0]);
      this.cityByZipCode.setValue(citydto.city.nom, citydto.city.codesPostaux[0])


      // if (citydto.originOfSelection == 'city-by-name') {
      //   this.profileFormBuilderService.city.patchValue(citydto.city.nom);  
      //   this.profileFormBuilderService.zipcode.patchValue (citydto.city.codesPostaux[0]);
      //   this.cityByZipCode.setValue(citydto.city.nom, citydto.city.codesPostaux[0])
      // }
      // else {
      //   console.log (citydto);
      //   this.profileFormBuilderService.city.patchValue(citydto.city.nom);  
      //   this.profileFormBuilderService.zipcode.patchValue (citydto.city.codesPostaux[0]);
      //   this.cityByName.setValue(citydto.city.nom, citydto.city.codesPostaux[0])
      // }
  }

   public createOrUpdateDmUser () {

        const toUpdateUser = new DmUser (null,
        this.profileFormBuilderService.firstname.value, 
        this.profileFormBuilderService.lastname.value, 
        this.profileFormBuilderService.num.value, 
        this.profileFormBuilderService.road.value, 
        this.profileFormBuilderService.additionalinfo.value, 
        this.profileFormBuilderService.city.value, 
        this.profileFormBuilderService.zipcode.value);
        console.log (toUpdateUser);
        this.sendDataService.createOrUpdateMe (toUpdateUser)
        .then ((result) => {
            console.log (result);
            this.successMessageEdited = true;
            setTimeout(function() {
              this.successMessageEdited = false;
            }.bind(this), 3000);
          }
        )
        .catch(err => {
            console.log (err);
            this.errorMessageEdited = true;
            setTimeout(function() {
              this.errorMessageEdited = false;
            }.bind(this), 3000);
          }
        );
  }

  startChangePassword() {
    this.buttonIsDisabled = true
    const updatePassword = new DroneMatchUpdatePassword (this.authenticationService.getUserName(), 
    this.changePasswordFormBuilderService.currentPassword.value,
    this.changePasswordFormBuilderService.newPassword.value, 
    this.changePasswordFormBuilderService.newPasswordRetaped.value);
    this.sendDataService.updatePassword (updatePassword)
      .then((result) => {
        if (result === 'UPDATED') {
          this.successMessageActived = true; 
          setTimeout(function() {
            this.successMessageActived = false;
            this.buttonIsDisabled=false; 
            this.data.changeMessage("Others");
            setTimeout(function() {
            }.bind(this), 1000);
            this.authenticationService.logout();
          }.bind(this), 3000);
        }
      },
      (error) => {
        this.errorMessageActived = true; 
        this.textError = error.error.message
        this.buttonIsDisabled = true
           setTimeout(function() {
            this.buttonIsDisabled = false
            setTimeout(function() {
              this.errorMessageActived = false; 
              this.textError = '' 
            }.bind(this), 3000);
          }.bind(this), 1000);
      }
    );
  }
 disabled () {
  
  if (  this.changePasswordFormBuilderService.changePasswordFormGroup.valid &&
        this.changePasswordFormBuilderService.newPassword.value !== '' &&
        this.changePasswordFormBuilderService.newPasswordRetaped.value != '')
        if (this.changePasswordFormBuilderService.newPassword.value
                   === this.changePasswordFormBuilderService.newPasswordRetaped.value) {
          return false;
        }
        else 
        {
          return true
        } 
  else 
  {
    return true;
  }

  // if (this.changePasswordFormBuilderService.changePasswordFormGroup.valid && 
  //   this.changePasswordFormBuilderService.newPassword.value && 
  //   this.changePasswordFormBuilderService.newPasswordRetaped.value && 
  //   this.changePasswordFormBuilderService.newPassword === this.changePasswordFormBuilderService.newPasswordRetaped)
  //   {
  //     this.buttonIsDisabled = false;
  //     return false;
  //   }
  //   else 
  //     return true;
 }
  public getFirstnameFormControl() {
      return this.profileFormBuilderService.firstname;
  }

  public getLastnameFormControl() {
    return this.profileFormBuilderService.lastname;
  }

  public getNumFormControl() {
    return this.profileFormBuilderService.num;
  }

  public getRoadFormControl() {
    return this.profileFormBuilderService.road;
  }

  public getAddtionalInfoFormControl() {
    return this.profileFormBuilderService.additionalinfo;
  }

  public getCityFormControl() {
    return this.profileFormBuilderService.city;
  }
  
  public getZipcodeFormControl() {
    return this.profileFormBuilderService.zipcode;
  }
  
    
  public getCurrentPasswordFormControl() {
    return this.changePasswordFormBuilderService.currentPassword;
  }
    
  public getNewPasswordFormControl() {
    return this.changePasswordFormBuilderService.newPassword;
  }
    
  public getNewPasswordRetapedFormControl() {
    return this.changePasswordFormBuilderService.newPasswordRetaped;
  }
}

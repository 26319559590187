import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AbstractDataService {

  SERVER_URL = environment.apiUrl;
  CONTEXT = environment.context;

  // Config locale
  public DRONE_MATCH_CONFIGURATION = {
    version: '0.0.1',
    EndpointsApi: {
      servertime:                  `${this.SERVER_URL}${this.CONTEXT}/start/time`,
      generatecptcha:              `${this.SERVER_URL}${this.CONTEXT}/start/generatecaptcha`,
      createaccount:               `${this.SERVER_URL}${this.CONTEXT}/start/createaccount`,
      reinitializepassword:        `${this.SERVER_URL}${this.CONTEXT}/start/reinitializepassword`,
      login:                       `${this.SERVER_URL}${this.CONTEXT}/start/login`,
      refreshtoken:                `${this.SERVER_URL}${this.CONTEXT}/start/refreshtoken`,
      citiesdept:                  `${this.SERVER_URL}${this.CONTEXT}/start/cities/dept`,
      citiesname:                  `${this.SERVER_URL}${this.CONTEXT}/start/cities/name`,
      collectivitybyprefix:        `${this.SERVER_URL}${this.CONTEXT}/start/collectivities`,
      regions:                     `${this.SERVER_URL}${this.CONTEXT}/start/regions`,
      globaljobannouncementfinder: `${this.SERVER_URL}${this.CONTEXT}/start/find/jobannouncement/`,
      
      userinfo:                     `${this.SERVER_URL}${this.CONTEXT}/api/userinfo`,
      material:                     `${this.SERVER_URL}${this.CONTEXT}/api/material`,
      company:                      `${this.SERVER_URL}${this.CONTEXT}/api/company`,
      me:                           `${this.SERVER_URL}${this.CONTEXT}/api/me`,
      jobannouncement:              `${this.SERVER_URL}${this.CONTEXT}/api/jobannouncement`,
      updatepassword:               `${this.SERVER_URL}${this.CONTEXT}/api/updatepassword`,

      quotationpilote:              `${this.SERVER_URL}${this.CONTEXT}/api/pilote/quotations`,
      pilotejobannouncement:        `${this.SERVER_URL}${this.CONTEXT}/api/pilote/jobannouncement`,
      piloteQuotationCheck:         `${this.SERVER_URL}${this.CONTEXT}/api/pilote/quotations/check`,
      pilotejobannouncementfinder:  `${this.SERVER_URL}${this.CONTEXT}/api/pilote/find/jobannouncement`,

      jobannouncemententreprise:    `${this.SERVER_URL}${this.CONTEXT}/api/entreprise/jobannouncement`,
      quotationentreprise:          `${this.SERVER_URL}${this.CONTEXT}/api/entreprise/quotations`
    }
  };
  constructor() {
  }
}

import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoadDataService } from 'src/app/services/data/load-data.service';
import { SendDataService } from 'src/app/services/data/send-data.service';
import { StoreService } from 'src/app/services/store/store.service';
import {Observable} from 'rxjs';
import { City } from 'src/app/model/city';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

/**
 * supposed to be the generic comonent for city and zipcode selection  
 */
@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css']
})
export class CityComponent implements OnInit {

  // used in edit case => come from parent component  
  @Input () findBy;
  @Input () label;
  
  @Input () cityName;

  // used in edit case  => come from parent component 
  @Input () zipCode
  
  cities : City [] = [];

  myControl = new FormControl('');
  filteredOptions: Observable<City[]>;
  
  @Output ()
  onSelectCity : EventEmitter<City> = new EventEmitter<City>();

  @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;


  constructor(public sendDataService: SendDataService,
    public loadDataService: LoadDataService,
    public storeService: StoreService,
    ) { }

  ngOnInit(): void {
       if (this.cityName && this.zipCode) {
        const city = new City(this.cityName, this.zipCode); 
        this.myControl.setValue (city) 
      }
      this.myControl.valueChanges.subscribe( (findStr) => {
          this.autoCompleteCity (findStr);   
        }
      );
  }
  setValue() {
    let options = this._auto.autocomplete.options.toArray()
    if (this.findBy != "dept") {
      this.myControl.setValue(this.cityName)
    }
    else 
    {
      this.myControl.setValue(this.zipCode)
    }
  }

  autoCompleteCity (findStr : string) {
    if (findStr.length > 1) {
      if (findStr.length < 3) {
          if (this.findBy == "dept") {
              this.loadDataService.loadCities (findStr).then ((cities) => {
                console.log(cities);
              this.cities=cities;
            });
          }
          else {
            this.loadDataService.loadCitiesByNamePrefix (findStr).then ((cities) => {
              this.cities=cities;
            });
          }
      }
      else {
        if (this.findBy == "dept") {
          this.cities = this.cities.filter(city => city.codesPostaux.join(',').toLowerCase().includes(findStr));
        }
        else {
          this.cities = this.cities.filter(city => city.nom.toLowerCase().includes(findStr));
        }

      }
    }
  }


  display (city : any) {

    if  (this.findBy != undefined) {
      if (this.findBy != "dept") {
        return city.nom;
      }
      else {
          return city.codesPostaux[0];  
      }
    }
  }

  filterCity (event) {
    console.log (event.source.value);
    const selectedCity = event.source.value as City; 
    this.onSelectCity.emit (selectedCity);
  }


}

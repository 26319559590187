import { Injectable } from '@angular/core';
import { PilotMaterial } from 'src/app/model/pilot-material';

@Injectable({
  providedIn: 'root'
})
export class PilotMaterialFormInitService {

  constructor() { }


  /**
   * create the gabarit of the form and initialize it 
   * 
   *  * initialize all form controls and all controls validators
   * 
   * @returns 
   * 
   */
   init = (): {[key: string]: any;} => {
    return {
      type: [''],
      model: [''],
      definition: [''],
      thermique: [''],
      classe: [''],
      securite: [''],
      materialImage: [null],
      createdate: [], 
      updatedate: [] 
     }; 
  }

  initForUpdate = (pilotMaterial : PilotMaterial ): {[key: string]: any;} => {
    return {
      type: [pilotMaterial.type],
      model: [pilotMaterial.model],
      definition: [pilotMaterial.definition],
      thermique: [pilotMaterial.thermique], 
      classe: [pilotMaterial.classe], 
      securite: [pilotMaterial.securite],
      // should be set to null ==> because it should be setted to empty if we are updating 
      materialImage: [null],
      createdate: [pilotMaterial.createdate], 
      updatedate: [pilotMaterial.updatedate] 
    }; 
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from 'src/app/model/company';
import { DmUser } from 'src/app/model/dmuser';
import { CookieService } from '../cookie/cookie.service';
import { StoreService } from '../store/store.service';
 import { City } from 'src/app/model/city';
import { Collectivity } from 'src/app/model/collectivity';
import { AuthenticationService } from '../authentication/authentication.service';
import { Region } from 'src/app/model/region';
import { AbstractDataService } from './abstract.-data.service';


@Injectable({
  providedIn: 'root'
})
export class LoadDataService extends AbstractDataService { 

  constructor ( protected http: HttpClient, 
                public cookieService: CookieService,
                public storeService: StoreService, 
                public auththenticationService: AuthenticationService
                ) {
       super();
  }

  public get configuration() {
    return this.DRONE_MATCH_CONFIGURATION;
  }


   
  public loadRegions(): Promise<Region[]> {
    return this.http.get(this.DRONE_MATCH_CONFIGURATION.EndpointsApi.regions)
      .toPromise()
      .then((regions) => {
        return regions as Region[];
      });
  }

   
  public loadCollectivitiesByPrefix(prefix): Promise<Collectivity[]> {
    return this.http.get(this.DRONE_MATCH_CONFIGURATION.EndpointsApi.collectivitybyprefix+"/" + prefix)
      .toPromise()
      .then((collectivities) => {
        return collectivities as Collectivity[];
      });
  }



    // Services for current user *

    public async getCurrentUser(): Promise<DmUser> {

      return this.http.get(this.DRONE_MATCH_CONFIGURATION.EndpointsApi.me)//, {headers : headersValues})
        .toPromise()
        .then((user) => {
            return user as DmUser;
        });
    }
  
  
  // Services for company *

  public async getCurrentCompany(): Promise<Company> {
    return this.http.get(this.DRONE_MATCH_CONFIGURATION.EndpointsApi.company)
      .toPromise()
      .then((company) => {
          return company as Company;
      });
  }

  
  public loadCities(deptCode): Promise<City[]> {
    return this.http.get(this.DRONE_MATCH_CONFIGURATION.EndpointsApi.citiesdept+"/" + deptCode)
      .toPromise()
      .then((cities) => {
        this.storeService.dataStore.cities=cities as City [];
        return this.storeService.dataStore.cities;
      });
  }

  public loadCitiesByNamePrefix(namePrefix): Promise<City[]> {
    return this.http.get(this.DRONE_MATCH_CONFIGURATION.EndpointsApi.citiesname+"/" + namePrefix)
      .toPromise()
      .then((cities) => {
        this.storeService.dataStore.cities=cities as City [];
        return this.storeService.dataStore.cities;
      });
  }

   // Services captcha 
  public loadCaptcha(): Promise<any> {
    return this.http.get(this.DRONE_MATCH_CONFIGURATION.EndpointsApi.generatecptcha, {headers : this.getHeader()})
      .toPromise()
      .then(captcha => {
        const originalCaptcha = Object.assign(this.storeService.dataStore.captcha, captcha);
        this.storeService.dataStore.captcha = originalCaptcha;
        return this.storeService.dataStore.captcha;
      });
  }

  public getHeader () : HttpHeaders {
    let headers = new HttpHeaders (); 
    headers.append('Accept', 'application/json');
    return headers;
  }
}
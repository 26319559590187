import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges  } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'component-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.css']
})
export class CaptchaComponent implements OnInit, OnChanges {


  // captcha image as base 64 string
  @Input() image;
  
  @Input() imageHeight;
  
  @Input() imageWidth;
  
  // not robot message
  @Input() notRobotMessage;

  @Input() inputTextMessage;

  @Input() cryptedValue;
  
  @Input() restartImagePath

  @Output() inputValueChange: EventEmitter<String> = new EventEmitter();
  
  @Output() refreshImageChange: EventEmitter<boolean> = new EventEmitter();

  @Output() iamNotARobotChange: EventEmitter<boolean> = new EventEmitter();

  base64Image : string
  
  control = new FormControl(); 
  checkBoxControl = new FormControl(false); 
  

  refreshCaptcha : boolean; 

  constructor(private sanitizer:DomSanitizer) { 
  }
  
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    if (changes.image.currentValue) {
      this.control.setValue ('');
      this.checkBoxControl.setValue(false);
    }
  }

  ngOnInit() {
  }

  transform() {
    this.base64Image = 'data:image/png;base64, ' + this.image;
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
  }

  sendValue($event) {
    this.inputValueChange.emit($event.target.value);
  }

  startRefreshCaptcha () {
    this.refreshCaptcha = true 
    this.refreshImageChange.emit(true);
  }

  onCheckboxisRobotChange ($event) {
    this.iamNotARobotChange.emit(!$event.target.checked); 
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractUserInfo } from 'src/app/model/abstract-user-info';
import { UserInfo } from 'src/app/model/user-info';
import { CookieService } from '../cookie/cookie.service';
import { AbstractDataService } from './abstract.-data.service';


@Injectable({
  providedIn: 'root'
})
export class UserDataService extends AbstractDataService {

  constructor ( protected http: HttpClient, 
                public cookieService: CookieService,
              ) {
                super();
  }


  public getUserInfo(): Promise<UserInfo> {

      return this.http.get(this.DRONE_MATCH_CONFIGURATION.EndpointsApi.userinfo)
        .toPromise()
        .then (result => {
          let abstractUserInfo = result as AbstractUserInfo;
          if (abstractUserInfo && abstractUserInfo !== undefined) {
            return new UserInfo (abstractUserInfo.username, abstractUserInfo.authorities[0].name);
          }
          else {
            return null;
          } 
        });
  }
}

/**
 * represent an account to create 
 */
 export class DroneMatchUpdatePassword {
    
    /*
     * Le image au format base64 
     */
    email: string;

    /**
     * current password
     */
    password : string;

    /**
     * new password
     */
    newpassword : string;

    /**
     * new password validation
     */
    newpasswordretyped : string;

    constructor(email: string, password : string, newpassword : string, newpasswordretyped : string,) {
      this.email = email;
      this.password = password;
      this.newpassword = newpassword;
      this.newpasswordretyped = newpasswordretyped;
    }
}

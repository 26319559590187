import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SideNavbarComponent } from 'src/app/components/user/side-navbar/side-navbar.component';

@Injectable({
	providedIn: 'root'
  })
export class SidenavbarService {
	private sidenav: MatSidenav;
	private sideNavbarComponent : SideNavbarComponent;

	public setSidenav(sidenav) {
		this.sidenav = sidenav;
	}

	public setSidenavBar(sideNavbarComponent) {
		this.sideNavbarComponent = sideNavbarComponent;
	}

	public open() {
		return this.sidenav.open();
	}


	public close() {
		return this.sidenav.close();
	}

	public toggle(): void {
		this.sidenav.toggle();
	}

	public lockUnlockSideNavBar () {
		this.sideNavbarComponent.lockUnlockSideNavBar ();
	}
}
import { City } from "./city";

/**
 * represent a city for a cityByName or CityByZipcode component 
 * it shoul transfer a city with some additional information like 
 * - selected zip code (because we have an array of zip codes in city object )
 * - selected city name 
 * - and the origin of selection ==> we update profile on selection ==> where 
 *   do we are come from with values newly selected ? the CityByName component or the CityByZipcodeComponent 
 *   on any compnent the user make the action ?   
 */
 export class CityDto {
    
   originOfSelection : string;
   city : City;    
   cityName : string;
   zipCode : string;
   regionCode : string;
   regionName : string;
   departementName: string; 
   departementCode: string; 

   constructor ( originOfSelection : string, city : City , cityName : string, zipCode : string, 
     regionName : string, departmentCode : string, regionCode : string, departmentName : string)
   {
        this.originOfSelection = originOfSelection;
        this.city = city;
        this.cityName=cityName;
        this.zipCode=zipCode;
        this.regionName= regionName; 
        this.departementCode = departmentCode;
        this.regionCode= regionCode; 
        this.departementName = departmentName;
   }
}
  
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { CompanyComponent } from './components/user/company/company.component';
import { DashboardComponent } from './components/user/dashboard/dashboard.component';
import { JobAnnoncementComponent } from './components/user/job-announcement/job-announcement-crud/job-announcement.component';
import { JobAnnouncementListComponent } from './components/user/job-announcement/job-announcement-list/job-announcement-list.component';
import { MaterialCrudComponent } from './components/user/pilot-material/material-crud/material-crud.component';
import { MaterialListComponent } from './components/user/pilot-material/material-list/material-list.component';
import { ProfileComponent } from './components/user/profile/profile.component';
import { UserHomeComponent } from './components/user/user-home/user-home.component';
import { AuthenticationGuardService } from './services/authentication/authentication-guard.service';
import { QuotationsListComponent } from './components/user/quotation/quotations-list/quotations-list.component';
import { QuotationCrudComponent } from './components/user/quotation/quotation-crud/quotation-crud.component';
import { QuotationJobannouncementComponent } from './components/user/quotation/quotation-jobannouncement/quotation-jobannouncement.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { CgvComponent } from './components/cgv/cgv.component';
import { CguComponent } from './components/cgu/cgu.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PdcComponent } from './components/pdc/pdc.component';
import { FaqComponent } from './components/faq/faq.component';


const routes: Routes = [
  { path: '', component: HomeComponent,pathMatch: 'full'},
  { path: 'cgv', component: CgvComponent,pathMatch: 'full'},
  { path: 'cgu', component: CguComponent,pathMatch: 'full'},
  { path: 'pdc', component: PdcComponent,pathMatch: 'full'},
  { path: 'faq', component: FaqComponent,pathMatch: 'full'},
  { path: 'create-account-pilote', component: CreateAccountComponent },
  { path: 'create-account-client', component: CreateAccountComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'my-home', component: UserHomeComponent, 
      children: [
          // shared screen
          { path: 'dashboard', component: DashboardComponent , canActivate: [AuthenticationGuardService], data: { roles: ['PILOTE', 'ENTREPRISE'] }},
          { path: 'my-profile', component: ProfileComponent , canActivate: [AuthenticationGuardService], data: { roles: ['PILOTE', 'ENTREPRISE'] }},
          { path: 'company', component: CompanyComponent , canActivate: [AuthenticationGuardService], data: { roles: ['PILOTE', 'ENTREPRISE'] }},
          // Entreprise screen
          { path: 'my-job-announcement', component: JobAnnouncementListComponent , canActivate: [AuthenticationGuardService], data: { roles: ['ENTREPRISE'] }},
          { path: 'create-job-announcement', component: JobAnnoncementComponent , canActivate: [AuthenticationGuardService], data: { roles: ['ENTREPRISE'] }},
          { path: 'modify-job-announcement/:id', component: JobAnnoncementComponent , canActivate: [AuthenticationGuardService], data: { roles: ['ENTREPRISE'] }},
          { path: 'quotation-job-announcement', component: QuotationJobannouncementComponent , canActivate: [AuthenticationGuardService], data: { roles: ['ENTREPRISE'] }},
          { path: 'payment', component: PaymentComponent , canActivate: [AuthenticationGuardService], data: { roles: ['ENTREPRISE'] }},
          // Pilote screen 
          { path: 'create-quotation/job-announcement/:jobannouncementid', component: QuotationCrudComponent , canActivate: [AuthenticationGuardService], data: { roles: ['PILOTE'] }},
          { path: 'edit-quotation/job-announcement/:joabannouncementid/quotation/:quotationid', component: QuotationCrudComponent , canActivate: [AuthenticationGuardService], data: { roles: ['PILOTE'] }},
          { path: 'quotations-list', component: QuotationsListComponent , canActivate: [AuthenticationGuardService], data: { roles: ['PILOTE'] }},
          { path: 'list-materials', component: MaterialListComponent , canActivate: [AuthenticationGuardService], data: { roles: ['PILOTE'] }},
          { path: 'my-material', component: MaterialCrudComponent , canActivate: [AuthenticationGuardService], data: { roles: ['PILOTE'] }},
          { path: 'my-material/:id', component: MaterialCrudComponent , canActivate: [AuthenticationGuardService], data: { roles: ['PILOTE'] }}
      ]
  },
  { path: 'logout', component: HomeComponent, canActivate: [AuthenticationGuardService], data: { roles: ['PILOTE', 'ENTREPRISE']} },
  {path:'**',redirectTo:'',pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { DmUser } from 'src/app/model/dmuser';

@Injectable({
  providedIn: 'root'
})
export class ProfileFormInitService {

  constructor() { }

  
  initForUpdate = (dmuser : DmUser ): {[key: string]: any;} => {
    return {
      firstname: [dmuser.firstname],
      lastname: [dmuser.lastname],
      num: [dmuser.num],
      road: [dmuser.road], 
      additionalinfo: [dmuser.additionalinfo], 
      city: [dmuser.city],
      zipcode: [dmuser.zipcode]
    }; 
  }

}

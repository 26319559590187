import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from 'src/app/model/company';
import { DmUser } from 'src/app/model/dmuser';
import { DroneMatchAccount } from 'src/app/model/drone-match-account';
import { LoginUser } from 'src/app/model/login-user';
import { TokenInfo } from 'src/app/model/token-info';
import { AuthenticationService } from '../authentication/authentication.service';
import { CookieService } from '../cookie/cookie.service';
import { AbstractDataService } from './abstract.-data.service';
import { DroneMatchUpdatePassword } from 'src/app/model/drone-match-update-password';

@Injectable({
  providedIn: 'root'
})
export class SendDataService extends AbstractDataService {

  constructor(  protected http: HttpClient, 
                public authenticationService: AuthenticationService, 
                public cookieService: CookieService ) {
    super();
  }


  public get configuration() {
    return this.DRONE_MATCH_CONFIGURATION;
  }

  public updatePassword (updatePassword: DroneMatchUpdatePassword): Promise<any> {
    return this.http.post(this.DRONE_MATCH_CONFIGURATION.EndpointsApi.updatepassword, updatePassword)
      .toPromise ()
      .then (
        result => {
          return result;
        }
      );
  }


  
  public reinitializePassword (account: DroneMatchAccount): Promise<any> {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    
    let headers: HttpHeaders = new HttpHeaders(headerDict);
    return this.http.post(this.DRONE_MATCH_CONFIGURATION.EndpointsApi.reinitializepassword, account, {headers})
      .toPromise ()
      .then (
        result => {
          return result;
        }
      );
  }


  public createAccount(account: DroneMatchAccount): Promise<any> {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    
    let headers: HttpHeaders = new HttpHeaders(headerDict);
    return this.http.post(this.DRONE_MATCH_CONFIGURATION.EndpointsApi.createaccount, account, {headers})
      .toPromise ()
      .then (
        result => {
          return result;
        }
      );
  }

  async login(user: LoginUser): Promise<any> {
    return this.http.post(this.DRONE_MATCH_CONFIGURATION.EndpointsApi.login, user)
      .toPromise()
      .then(tokenInfo => {
        const ti  = tokenInfo as TokenInfo ; 
        this.authenticationService.setAccessToken (ti.access_token);
        this.authenticationService.setRefreshToken (ti.refresh_token);
        return ti;
      });
  }

  public createOrUpdateCompany (company: Company): Promise<any> {

    
    return this.http.post ( 
              this.DRONE_MATCH_CONFIGURATION.EndpointsApi.company, 
              company)
      .toPromise ()
      .then (
        result => {
          return result;
        }
      );
  }

  public createOrUpdateMe (user: DmUser): Promise<any> {

      return this.http.post ( 
              this.DRONE_MATCH_CONFIGURATION.EndpointsApi.me, 
              user)
      .toPromise ()
      .then (
        result => {
          return result;
        }
      );
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/shared-nf-component/base-component';
import { JobAnnouncement } from 'src/app/model/job-announcement';
import { JobAnnouncementDataService } from 'src/app/services/data/job-announcement-data.service';
import { QuotationDataService } from 'src/app/services/data/quotation-data.service';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import { JobAnnouncementFormBuilderService } from 'src/app/services/user-services/job-announcement/job-announcement-form-builder.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuotationLight } from 'src/app/model/quotation-light';


@Component({
  selector: 'app-display-job-announcement',
  templateUrl:'./display-job-announcement.component.html',
  styleUrls: ['./display-job-announcement.component.css'],
})
export class DisplayJobAnnoncementComponent extends BaseComponent implements OnInit {
  id :number;
  jobAnnouncement : JobAnnouncement;
  buttonText = "Créer annonce"; 
  buttonColor = "primary";
  successMessageEdited = false;
  errorMessageEdited = false;
  message:string
  existingdevis: boolean = true;

  devisForm = new FormGroup({
    price: new FormControl('',Validators.required),
    availability: new FormControl('',Validators.required)
  });
  constructor ( public jobAnnouncementFormBuilderService : JobAnnouncementFormBuilderService,
                public jobAnnouncementDataService: JobAnnouncementDataService,
                protected route: ActivatedRoute,
                public router: Router,
                public data: ShareDataService,
                public quotationDataService : QuotationDataService ) { 
                  super(data)
  }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    if ( this.id !== 0 ) {  
      this.buttonText = "Mettre à jour";
      // TODO  to change with update color style
      this.buttonColor = "primary";
      this.jobAnnouncementDataService.getPiloteJobAnnouncementById(this.id).then
          ((result) => {this.jobAnnouncement = result;
            this.jobAnnouncementFormBuilderService.jobAnnouncementFormGroup = this.jobAnnouncementFormBuilderService.buildForUpdate(this.jobAnnouncement);
          });
    }
    else {
      this.jobAnnouncementFormBuilderService.jobAnnouncementFormGroup = this.jobAnnouncementFormBuilderService.build();
    }

    this.checkDevis();
  }

  onSubmit() {
    console.warn(this.devisForm.value);
    this.quotationDataService.createQuotation(
      new QuotationLight('',
        this.id.toString(),
        this.devisForm.controls['price'].value,
        this.devisForm.controls['availability'].value)
      );
  }

  checkDevis():void{
    this.quotationDataService.checkExistingQuotation(this.id).subscribe(
      x=>{
        if(!x){
          this.existingdevis=false;}
        else this.existingdevis = true;
      },
      err=>console.log("err"+err)
    )
  }


  public getTitleFormControl() {
    return this.jobAnnouncementFormBuilderService.title;
  }

  public getDescriptionFormControl () { 
    return this.jobAnnouncementFormBuilderService.description;
  }

  public getCityFormControl () {
    return this.jobAnnouncementFormBuilderService.city;
  }

  public getStartingdateFormControl () {
    return this.jobAnnouncementFormBuilderService.startingdate;
  }

  public getDurationFormControl () {
    return this.jobAnnouncementFormBuilderService.duration;
  }

  public getTagsFormControl () {
    return this.jobAnnouncementFormBuilderService.tags;
  }

  public getStatusFormControl () {
    return this.jobAnnouncementFormBuilderService.status;
  }

  public getCreatedateFormControl () {
    return this.jobAnnouncementFormBuilderService.createdate;
  }

  public getUpdatedateFormControl () {
    return this.jobAnnouncementFormBuilderService.updatedate;
  }

  public getNbviewsFormControl () {
    return this.jobAnnouncementFormBuilderService.nbviews;
  }
}

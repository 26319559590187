import { Injectable } from '@angular/core';
import { City } from 'src/app/model/city';
import { JobAnnouncement } from 'src/app/model/job-announcement';


export interface StoredData {
  captcha: any;
  jobAnnouncementList : JobAnnouncement[];
  cities : City[];
}

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  
  public dataStore: StoredData = {
    captcha: {} ,
    jobAnnouncementList : [],
    cities: []
  };

  constructor() { }
}

import { Collectivity } from 'src/app/model/collectivity';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoadDataService } from 'src/app/services/data/load-data.service';
import { SendDataService } from 'src/app/services/data/send-data.service';
import { StoreService } from 'src/app/services/store/store.service';
import {Observable} from 'rxjs';
import { Region } from 'src/app/model/region';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.css']
})
export class RegionsComponent implements OnInit {

  regions : Region [] = [];
  tmpregions : Region [] = [];
 
   myControl = new FormControl('');
   filteredOptions: Observable<Region[]>;
   
   @Output ()
   onSelectRegion : EventEmitter<Region> = new EventEmitter<Region>();

 
  constructor( public sendDataService: SendDataService,
                public loadDataService: LoadDataService,
                public storeService: StoreService ) {
  }
 
   ngOnInit(): void {
      
      this.loadDataService.loadRegions().then ((regions) => {
        this.regions=regions;
        this.tmpregions=regions;
      });

      this.myControl.valueChanges.subscribe( (findStr) => {
            this.autoCompleteRegion (findStr);   
            if (findStr ==='') {
              this.onSelectRegion.emit (new Region('',''));
            }
        }
      );
   }

   autoCompleteRegion (findStr : any) {
      
      if (typeof findStr === 'string') {
        findStr = findStr.toLowerCase();
        this.regions = this.tmpregions.filter(region =>
          region.nom.toLowerCase().includes(findStr)
        );
      }
   }

   display (region : any) {
      return region.nom;
   }
 
   filterRegion (event) {
    // ignore deselection of previous value 
    if (event.isUserInput) {
      const selectedRegion = event.source.value as Region; 
      this.onSelectRegion.emit (selectedRegion);
    }
   }
}

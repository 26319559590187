import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DmUser } from 'src/app/model/dmuser';
import { ProfileFormInitService } from './profile-form-init.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileFormBuilderService {
  public profileFormGroup : FormGroup;

  constructor( private profileFormBuilder : FormBuilder, 
              public profileFormInitService : ProfileFormInitService ) { }

  buildForUpdate = (dmuser : DmUser) : FormGroup => {
    return this.profileFormBuilder.group (this.profileFormInitService.initForUpdate(dmuser));
  }

  public get firstname () : FormControl {
    return this.profileFormGroup.get('firstname') as FormControl;
  }

  public get lastname () : FormControl {
    return this.profileFormGroup.get('lastname') as FormControl;
  }

  public get num () : FormControl {
    return this.profileFormGroup.get('num') as FormControl;
  }

  public get road () : FormControl {
    return this.profileFormGroup.get('road') as FormControl;
  }

  public get additionalinfo () : FormControl {
    return this.profileFormGroup.get('additionalinfo') as FormControl;
  }

  public get city () : FormControl {
    return this.profileFormGroup.get('city') as FormControl;
  }

  public get zipcode () : FormControl {
    return this.profileFormGroup.get('zipcode') as FormControl;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from 'src/app/services/cookie/cookie.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { QuotationLight } from 'src/app/model/quotation-light';
import { Quotation } from 'src/app/model/quotation';
import { QuotationLightPilote } from 'src/app/model/quotation-light-pilote';
import { AbstractDataService } from './abstract.-data.service';

@Injectable({
  providedIn: 'root'
})
export class QuotationDataService extends AbstractDataService {

  private createQuotationPiloteUrl = this.DRONE_MATCH_CONFIGURATION.EndpointsApi.quotationpilote;
  private quotationEntrepriseUrl = this.DRONE_MATCH_CONFIGURATION.EndpointsApi.quotationentreprise;
  private jobannouncementEntrepriseUrl = this.DRONE_MATCH_CONFIGURATION.EndpointsApi.jobannouncemententreprise;
  private piloteQuotationCheckUrl = this.DRONE_MATCH_CONFIGURATION.EndpointsApi.piloteQuotationCheck;


  constructor(  
    private http: HttpClient,  
    public cookieService: CookieService,
    public authenticationService: AuthenticationService) {
    super();
  }
  
  
  public createQuotation (quotationLight: QuotationLight): Promise<any> {
   
    return this.http.post ( 
              this.createQuotationPiloteUrl, 
              quotationLight)
      .toPromise ()
      .then (
        result => {
          return result;
        }
      );
  }

  public getQuotationsList(): Observable<QuotationLightPilote[]> {
    return this.http
      .get(this.createQuotationPiloteUrl)
      .pipe<QuotationLightPilote[]>(map((data: any) => data));
  }

  public getQuotationById(quotationid : number): Observable<Quotation> {
    return this.http
      .get(this.createQuotationPiloteUrl+`/${quotationid}`)
      .pipe<Quotation>(map((data: any) => data));
  }


  public checkExistingQuotation(annonceid : number){
    return this.http
      .get(this.piloteQuotationCheckUrl+"?annonceid="+annonceid)
      .pipe<Quotation>(map((data: any) => data));
  }


  // Entreprise 
  
  public getQuotationsEntrepriseList(jobAnnouncementId : any): Observable<QuotationLight[]> {
    return this.http
      .get(this.jobannouncementEntrepriseUrl+`/${jobAnnouncementId}/quotations`)
      .pipe<QuotationLight[]>(map((data: any) => data));
  }

    
  public acceptQuotationOnJobAnnouncement (quotationid : any, jobannouncementid : any): Observable<Quotation> {
    return this.http
      .put(this.quotationEntrepriseUrl+`/${quotationid}/jobannouncement/${jobannouncementid}/validate`, null)
      .pipe<Quotation>(map((data: any) => data));
  }

  public deleteQuotation (id: number) : Observable<number> {
    return this.http.delete<number>(
        `${this.createQuotationPiloteUrl}/${id}`)
        .pipe<number>(map((result: any) => result));
  }
}

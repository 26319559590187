import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PilotMaterial } from 'src/app/model/pilot-material';
import { PilotMaterialDataService } from 'src/app/services/data/pilot-material-data.service';
import { SendDataService } from 'src/app/services/data/send-data.service';
import { PilotMaterialFormBuilderService } from 'src/app/services/user-services/pilot-material/pilot-material-form-builder.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import { BaseComponent } from 'src/app/components/shared-nf-component/base-component';

@Component({
  selector: 'app-material-crud',
  templateUrl: './material-crud.component.html',
  styleUrls: ['./material-crud.component.css']
})
export class MaterialCrudComponent extends BaseComponent implements OnInit {

  id :number;
  pilotMaterial : PilotMaterial;
  buttonText = "Ajouter matériel"; 
  buttonColor = "primary";
  successMessageEdited = false;
  errorMessageEdited = false;
  url: any;
	msg = "";
  message:string
  constructor ( public pilotMaterialFormBuilderService : PilotMaterialFormBuilderService,
                public sendDataService: SendDataService,
                public pilotMaterialDataService: PilotMaterialDataService,
                private sanitizer: DomSanitizer,
                protected route: ActivatedRoute,
                public router: Router,
                public data: ShareDataService ) { 
                  super(data)

  }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    if ( this.id !== 0 ) {  
      this.buttonText = "Mettre à jour";
      // TODO  to change with update color style
      this.buttonColor = "primary";
      this.pilotMaterialDataService.getPilotMaterialById(this.id).then
          ((result) => {this.pilotMaterial = result;
            if (this.pilotMaterial.byteMaterialImage != "") {
              let objectURL = 'data:image/png;base64,' + this.pilotMaterial.byteMaterialImage;
              this.url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            }
            this.pilotMaterialFormBuilderService.pilotMaterialFormGroup = this.pilotMaterialFormBuilderService.buildForUpdate(this.pilotMaterial);
          });
    }
    else {
      this.pilotMaterialFormBuilderService.pilotMaterialFormGroup = this.pilotMaterialFormBuilderService.build();
    }
    this.hideNavbarOnHome();
  }

  hideNavbarOnHome() {
    this.data.changeMessage("Internal")
  }

  public createOrUpdateMaterial () {

      var id = '';
      if (this.id != null && this.id != 0) {
        id= '' + this.id;
      }
      // case of user does not selected an image file 
      // should initialize it to empty file 
      if(this.pilotMaterialFormBuilderService.materialImage.value == null)
      {
        this.pilotMaterialFormBuilderService.setMaterialImageValue (new File([], ''));
      }
      const material = new PilotMaterial (id,
      this.pilotMaterialFormBuilderService.type.value, 
      this.pilotMaterialFormBuilderService.model.value, 
      this.pilotMaterialFormBuilderService.definition.value, 
      this.pilotMaterialFormBuilderService.thermique.value, 
      this.pilotMaterialFormBuilderService.classe.value, 
      this.pilotMaterialFormBuilderService.securite.value, 
      this.pilotMaterialFormBuilderService.materialImage.value,
      new Date(this.pilotMaterialFormBuilderService.createdate.value), 
      new Date(this.pilotMaterialFormBuilderService.updatedate.value)); 
      
      // Dto should be transformed to FormData type 
      let formData : FormData =material.toFormData ();
    
      this.pilotMaterialDataService.createMaterial (formData)
      .then ((result) => {
          console.log (result);
          this.successMessageEdited = true;
          setTimeout(function() {
            this.successMessageEdited = false;
          }.bind(this), 3000);
        }
      )
      .catch(err => {
          this.errorMessageEdited = true;
          setTimeout(function() {
            this.errorMessageEdited = false;
          }.bind(this), 3000);
        }
      );
  }

  
  selectFile(event) {

    if(!event.target.files[0] || event.target.files[0].length == 0) {
			this.msg = 'Vous devez selectionner une image';
			return;
		}
		
		var mimeType = event.target.files[0].type;
		
		if (mimeType.match(/image\/*/) == null) {
			this.msg = "Uniquement les images sont supportés : ";
			return;
		}

    this.pilotMaterialFormBuilderService.setMaterialImageValue (event.target.files[0]);
    var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		
		reader.onload = (_event) => {
			this.msg = "";
			this.url = reader.result; 
		}
  }


  public getTypeFormControl () {
    return this.pilotMaterialFormBuilderService.type;
  }
  public getModelFormControl () {
    return this.pilotMaterialFormBuilderService.model;
  }
  public getDefinitionFormControl () {
    return this.pilotMaterialFormBuilderService.definition;
  }
  public getThermiqueFormControl () {
    return this.pilotMaterialFormBuilderService.thermique;
  }
  public getClasseFormControl () {
    return this.pilotMaterialFormBuilderService.classe;
  }
  public getSecuriteFormControl () {
    return this.pilotMaterialFormBuilderService.securite;
  }



  public getMaterialImageFormControl () {
    return this.pilotMaterialFormBuilderService.materialImage;
  }
  
  public getCreatedateFormControl () {
    return this.pilotMaterialFormBuilderService.createdate;
  }

  public getUpdatedateFormControl () {
    return this.pilotMaterialFormBuilderService.updatedate;
  }

}

/**
 * 
 * upload File sample
 *  
 * https://medium.com/@ashwin.jammihal/upload-both-request-body-and-multipart-file-using-springs-resttemplate-8207ab6069b6
 * 
 * Upload FormDta sample 
 * 
 * https://stackoverflow.com/questions/68837794/angular-spring-boot-file-upload-example
 * 
 * 
 * patch formgroup value for file type 
 * 
 * https://www.itsolutionstuff.com/post/file-upload-with-angular-reactive-forms-exampleexample.html
 * 
 * should remove formControlName value 
 * https://stackoverflow.com/questions/49970970/angular-5-file-upload-failed-to-set-the-value-property-on-htmlinputelement
 * 
 * 
 */
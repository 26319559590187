import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    isLoguedIn = false;
    @Input() title: string = 'Welcome to Home';
    @Input() showButtons: string = 'true';

    
    private toggleButton: any;
    private sidebarVisible: boolean;

    message:string;
    navbar : HTMLElement;

    constructor( private autenticationService: AuthenticationService,
                 public location: Location, 
                 private element : ElementRef,
                 private data: ShareDataService ) {
        this.sidebarVisible = false;
        this.isLoguedIn = this.autenticationService.isLoggedIn();
        this.data.currentMessage.subscribe(message => this.message = message);
    }

    ngOnInit() {
        this.navbar = this.element.nativeElement;
        this.toggleButton = this.navbar.getElementsByClassName('navbar-toggler')[0];
        this.data.currentMessage.subscribe(message => {this.message = message;
            if (message == "Internal")
                this.navbar.style.display = "none";
            else 
                this.navbar.style.display = "block";
         });
    }

    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };

    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };

    getUserName () {
        if (this.isLoguedIn) 
          return this.autenticationService.getUserName(); 
        else 
          return '';   
    };
    
    logout() {
        this.isLoguedIn = false;
        this.data.changeMessage("Others")
        this.autenticationService.logout(); 
    };

    login () { 
        
        this.myFunction();
    }

    myFunction() {

        var x = document.getElementById("myLinks");
        if (x.style.display === "block") {
            x.style.display = "none";
        } else {
            x.style.display = "block";
        }

        
        var i = document.getElementById("icon-id");
        if (i.style.color === "rgb(251, 198, 88)") {
            i.style.color = "#f5593d";
        } else {
            i.style.color = "#fbc658";
        }
        
        var n = document.getElementById("hamburger-id");
        console.log (n.style.color);

        if (n.style.borderColor === "rgb(251, 198, 88)") {
            n.style.borderColor = "#f5593d";
        } else {
            n.style.borderColor = "#fbc658";
        }
     }
}

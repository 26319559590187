import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/model/company';
import { LoadDataService } from 'src/app/services/data/load-data.service';
import { SendDataService } from 'src/app/services/data/send-data.service';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import { CompanyFormBuilderService } from 'src/app/services/user-services/company/company-form-builder.service';
import { BaseComponent } from '../../shared-nf-component/base-component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})

export class CompanyComponent extends BaseComponent implements OnInit {

  buttonText = "Créer Entreprise"; 
  buttonColor = "primary";
  successMessageEdited = false;
  errorMessageEdited = false;
  message : string
  dataFound = true;
  textError ="";

  constructor ( public companyFormBuilderService : CompanyFormBuilderService,
                public sendDataService: SendDataService,
                public loadDataService: LoadDataService,
                protected route: ActivatedRoute,
                public router: Router,
                public data: ShareDataService ) { 
                    super(data);
                }

  ngOnInit(): void {
    this.loadDataService.getCurrentCompany()
        .then (
            (company) => {
                if ( company != null || company != undefined ) {  
                    this.buttonText = "Mettre à jour";
                    // TODO  to change with update color style
                    this.buttonColor = "primary";
                    this.companyFormBuilderService.companyFormGroup = this.companyFormBuilderService.buildForUpdate(company);
                }
                else {
                    this.companyFormBuilderService.companyFormGroup = this.companyFormBuilderService.build();
                }
                this.dataFound=true  
            },
            (error) => {
              this.textError = error.error.message
              this.dataFound=false
            }
        );
  }


  public createOrUpdateCompany () {

        const company = new Company (null,
        this.companyFormBuilderService.companyname.value, 
        this.companyFormBuilderService.email.value, 
        this.companyFormBuilderService.phone.value, 
        this.companyFormBuilderService.siret.value, 
        this.companyFormBuilderService.siren.value, 
        this.companyFormBuilderService.contactway.value, 
        this.companyFormBuilderService.iban.value,
        this.companyFormBuilderService.bic.value,
        new Date(this.companyFormBuilderService.createdate.value), 
        new Date(this.companyFormBuilderService.updatedate.value));
        console.log (company);
        this.sendDataService.createOrUpdateCompany (company)
        .then ((result) => {
            console.log (result);
            this.successMessageEdited = true;
            setTimeout(function() {
              this.successMessageEdited = false;
            }.bind(this), 3000);
          }
        )
        .catch(err => {
            console.log (err);
            this.errorMessageEdited = true;
            this.textError = err.error.message
            setTimeout(function() {
              this.errorMessageEdited = false;
            }.bind(this), 3000);
          }
        );


  }

  public getCompanynameFormControl() {
      return this.companyFormBuilderService.companyname;
  }

  public getEmailFormControl() {
    return this.companyFormBuilderService.email;
  }

  public getPhoneFormControl() {
    return this.companyFormBuilderService.phone;
  }

  public getSiretFormControl() {
    return this.companyFormBuilderService.siret;
  }

  public getSirenFormControl() {
    return this.companyFormBuilderService.siren;
  }

  public getContactwayFormControl() {
    return this.companyFormBuilderService.contactway;
  }
  
  public getIbanFormControl() {
    return this.companyFormBuilderService.iban;
  }
  
  public getBicFormControl() {
    return this.companyFormBuilderService.bic;
  }
    
  public getCreatedateFormControl() {
    return this.companyFormBuilderService.createdate;
  }
    
  public getUpdatedateFormControl() {
    return this.companyFormBuilderService.updatedate;
  }
  
}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/shared-nf-component/base-component';
import { Quotation } from 'src/app/model/quotation';
import { QuotationLightPilote } from 'src/app/model/quotation-light-pilote';
import { QuotationDataService } from 'src/app/services/data/quotation-data.service';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';

@Component({
  selector: 'app-quotations-list',
  templateUrl: './quotations-list.component.html',
  styleUrls: ['./quotations-list.component.css']
})
export class QuotationsListComponent extends BaseComponent implements OnInit, AfterViewInit  {

  displayedColumns: string[] = quotationColumns.map((col) => col.key);
  columnsSchema: any = quotationColumns;
  dataSource = new MatTableDataSource<QuotationLightPilote>();
  valid: any = {};
  @ViewChild(MatPaginator) paginator: MatPaginator;
  successMessageDelete = false;
  errorMessageDelete = false;
  quotationList;
  constructor(
              public dialog: MatDialog, 
              private quotationService: QuotationDataService, private router : Router,
              protected route: ActivatedRoute, 
              public data: ShareDataService ) { 
      super(data)
    }

  ngOnInit() {
    this.quotationService.getQuotationsList().subscribe((res: any) => {
      //TODO le tri ne marche pas (pas de sort sur obj de type any?)
      res.sort((first, second) => {
        if (first.validitedevis !== null && second.validitedevis !== null) {
          return new Date(first.validitedevis).getTime() - new Date(second.validitedevis).getTime();
        }
      });
      this.quotationList = res;
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
    });
  }

  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  goToQuotation(row) {
    this.router.navigate([`my-home/edit-quotation/job-announcement/${row.jobannouncementid}/quotation/${row.id}`]);
  }

  removeRow(element) {
    this.quotationService.deleteQuotation(Number(element.id)).subscribe
    ((result) => {
      if ( result === 1 ) {
          this.dataSource.data = this.dataSource.data.filter(
            (u: QuotationLightPilote) => u.id !== element.id);
            this.successMessageDelete = true;
            setTimeout(function() {
            this.successMessageDelete = false;
          }.bind(this), 3000);
      }
      else {
        this.errorMessageDelete = true;
        setTimeout(function() {
          this.errorMessageDelete = false;
        }.bind(this), 3000);
      }
    },
    err=> {
      console.log(err); 
      this.errorMessageDelete = true;
      setTimeout(function() {
        this.errorMessageDelete = false;
      }.bind(this), 3000);

    });

    this.quotationService.getQuotationsList().subscribe((res: any) => {
      //TODO le tri ne marche pas (pas de sort sur obj de type any?)
      res.sort((first, second) => {
        if (first.validitedevis !== null && second.validitedevis !== null) {
          return new Date(first.validitedevis).getTime() - new Date(second.validitedevis).getTime();
        }
      });
      this.quotationList = res;
      this.dataSource.data = res;
      this.dataSource.paginator = this.paginator;
    });
  }
}

export const quotationColumns = [
  {
    key: 'id',
    type: 'text',
    label: 'Id Devis',
    required: true,
  },
  {
    key: 'titreAnnonce',
    type: 'text',
    label: 'Titre de l\'annonce',
  },
  {
    key: 'quotationref',
    type: 'text',
    label: 'Référence du devis',
  },
  {
    key: 'price',
    type: 'text',
    label: 'Prix proposé',
  },
  {
    key: 'availability',
    type: 'date',
    label: 'Date de validité',
  },
  {
    key: 'status',
    type: 'badge',
    label: 'Etat Devis',
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: '',
  },
];
        
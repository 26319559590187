import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SendDataService } from 'src/app/services/data/send-data.service';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import { BaseComponent } from 'src/app/components/shared-nf-component/base-component';
import { QuotationFormBuilderService } from 'src/app/services/user-services/quotation/quotation-form-builder.service';
import { QuotationLight } from 'src/app/model/quotation-light';
import { QuotationDataService } from 'src/app/services/data/quotation-data.service';
import { DataUtils } from 'src/app/utils/DataUtils';
import { JobAnnouncementDataService } from 'src/app/services/data/job-announcement-data.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-quotation-crud',
  templateUrl: './quotation-crud.component.html',
  styleUrls: ['./quotation-crud.component.css']
})
export class QuotationCrudComponent extends BaseComponent implements OnInit {

  private readonly maxLengthTitle = 70;

  breadCrumbText = "create"; 
  
  // identifier of the quotation to edit 
  jobannouncementid :number;
  quotationid :number;
  // status of the form ==> take true if there is 
  // a quotation to edit (id !== 0), false otherwise 
  isEditable : boolean; 


  // current quotation with its job announcement (link) 
  quotationFull : any;
  jobAnnouncement : any;
  buttonText = "Ajouter devis"; 
  buttonColor = "btn btn-warning";
  successMessageEdited = false;
  errorMessageEdited = false;
  showJobannouncementDescription : boolean = true;

  constructor ( private location: Location, 
                public quotationFormBuilderService : QuotationFormBuilderService,
                public sendDataService: SendDataService,
                public quotationDataService: QuotationDataService,
                public jobAnnouncementDataService: JobAnnouncementDataService,
                protected route: ActivatedRoute,
                public router: Router,
                protected dataUtils : DataUtils,
                public data: ShareDataService ) { 
      super(data)
  }

  ngOnInit(): void {
    this.jobannouncementid = Number(this.route.snapshot.paramMap.get('jobannouncementid'));
    this.quotationid = Number(this.route.snapshot.paramMap.get('quotationid'));
    
    if ( this.quotationid !== 0 ) {
      this.isEditable = true; 
      this.initEditQuotationView ();
    } 
    else 
    {
      this.isEditable = false;
      this.initCreateQuotationView ();
      this.quotationFormBuilderService.quotationFormGroup = this.quotationFormBuilderService.buildForCreate(this.jobannouncementid);
      this.showJobAnnouncementDetails ();
    }
  }

  public truncateTitle(title: string) {
    return this.dataUtils.displayTitle(title, this.maxLengthTitle);
  }

  public afficher () {
    this.showJobannouncementDescription = !this.showJobannouncementDescription
  }
  
  public createOrUpdateQuotation () {

      var id = '';
      if (this.quotationid != null && this.quotationid != 0) {
        id= '' + this.quotationid;
      }
      
      const quotation = new QuotationLight(
        ''+this.quotationid, 
        ''+this.jobannouncementid, 
        this.quotationFormBuilderService.price.value , 
        this.quotationFormBuilderService.availability.value, 
      );
      this.quotationDataService.createQuotation (quotation)
      .then ((result) => {
          this.quotationid=result.id;
          this.jobannouncementid=result.annonce.id;
          this.jobAnnouncement=result.annonce;
          this.quotationFull = result;
          this.isEditable = true; 
          this.location.go(`my-home/edit-quotation/job-announcement/${result.annonce.id}/quotation/${result.id}`);
          this.successMessageEdited = true;
          setTimeout(function() {
            this.successMessageEdited = false;
          }.bind(this), 3000);
        }
      )
      .catch(err => {
          this.errorMessageEdited = true;
          setTimeout(function() {
            this.errorMessageEdited = false;
          }.bind(this), 3000);
        }
      );
  }

  public getIdJobAnnouncementFormControl () {
    return this.quotationFormBuilderService.idJobAnnouncement;
  }
  public getAvailabilityFormControl () {
    return this.quotationFormBuilderService.availability;
  }
  public getPriceFormControl () {
    return this.quotationFormBuilderService.price;
  }
  public getStatusFormControl () {
    return this.quotationFormBuilderService.status;
  }
  public disableUpdateButton () {
    // if (this.quotationFull !== null && this.quotationFull !== undefined)
    //   return this.quotationFull.status !== 'CREATED'; 
    // else 
    //   return false;
    return false;
  }
  
  public initEditQuotationView () {

    this.breadCrumbText = "edit" 
    this.buttonText = "Editer";
    // TODO  to change with update color style
    this.buttonColor = "btn btn-warning";
    this.quotationDataService.getQuotationById(this.quotationid).subscribe
      ((result) => {
        this.quotationFull = result;
        this.jobAnnouncement = this.quotationFull.annonce;
        this.quotationFormBuilderService.quotationFormGroup = this.quotationFormBuilderService.buildForEdit(this.quotationFull);
      });
  
  }


  public showJobAnnouncementDetails () {

    this.jobAnnouncementDataService.getPiloteJobAnnouncementById(this.jobannouncementid).then
      ((result) => {
        this.jobAnnouncement = result;
      });
  }

  public initCreateQuotationView () {
    this.breadCrumbText = "create" 
    this.buttonText = "Créer";
    this.buttonColor = "btn btn-danger";
  }
}
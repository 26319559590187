/**
 * represent user compmany
 */
 export class Company {
    
   id : number;
   companyname : string;
   email : string;
   phone : string;
   siret: string;
   siren: string;
   contactway: string;
   iban: string;
   bic: string;
	createdate : Date;
	updatedate : Date;

   constructor ( id : number, companyname : string, email : string,
      phone : string, siret: string, siren: string, contactway: string, 
      iban: string, bic: string, createdate : Date, updatedate : Date
   )
   {
      this.id=id; 
      this.companyname=companyname;
      this.email=email;
      this.phone=phone;
      this.siret=siret;
      this.siren=siren;
      this.contactway=contactway;
      this.iban=iban;
      this.createdate=createdate;
      this.updatedate=updatedate;
      this.bic=bic;
   }
}
  
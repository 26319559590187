/**
 * represent a region
 */
export class Region {
    
    nom : string;
    code : string;

    constructor ( nom : string, code : string)
    {
         this.nom=nom; 
         this.code=code;
    }
 }
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Company } from 'src/app/model/company';
import { CompanyFormInitService } from './company-form-init.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyFormBuilderService {

  public companyFormGroup : FormGroup;

  constructor ( private companyFormBuilder : FormBuilder, 
              public companyFormInitService : CompanyFormInitService ) { }

  build = () : FormGroup => {
    return this.companyFormBuilder.group (this.companyFormInitService.init());
  }

  buildForUpdate = (company : Company) : FormGroup => {
    return this.companyFormBuilder.group (this.companyFormInitService.initForUpdate(company));
  }

  public get companyname () : FormControl {
    return this.companyFormGroup.get('companyname') as FormControl;
  }

  public get email () : FormControl {
    return this.companyFormGroup.get('email') as FormControl;
  }

  public get phone () : FormControl {
    return this.companyFormGroup.get('phone') as FormControl;
  }

  public get siret () : FormControl {
    return this.companyFormGroup.get('siret') as FormControl;
  }

  public get siren () : FormControl {
    return this.companyFormGroup.get('siren') as FormControl;
  }

  public get contactway () : FormControl {
    return this.companyFormGroup.get('contactway') as FormControl;
  }

  public get iban () : FormControl {
    return this.companyFormGroup.get('iban') as FormControl;
  }

  public get bic () : FormControl {
    return this.companyFormGroup.get('bic') as FormControl;
  }
  
  public get createdate () : FormControl {
    return this.companyFormGroup.get('createdate') as FormControl;
  }

  public get updatedate () : FormControl {
    return this.companyFormGroup.get('updatedate') as FormControl;
  }

}

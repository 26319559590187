import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DroneMatchAccount } from 'src/app/model/drone-match-account';
import { LoadDataService } from 'src/app/services/data/load-data.service';
import { SendDataService } from 'src/app/services/data/send-data.service';
import { UserDataService } from 'src/app/services/data/user-data.service';
import { ReinitializePasswordFormBuilderService } from 'src/app/services/reinitialize-password/reinitialize-password-builder.service';
import { StoreService } from 'src/app/services/store/store.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  title = 'Mot de passe oublié';
  captcha : string = '';
  robot : Boolean = true;
  show : boolean = false;
  updated : Boolean = false;
  captchaError : boolean = false;
  robotError : boolean = false;
  buttonIsDisabled : boolean = false;
  emailValidationError: boolean = false;
  textError : string = '';
  errorMessageActived:boolean = false; 
  successMessageActived: boolean = false; 
  innerWidth;
  authentication_header_background = 'authentication-header-background'; 

  constructor(public reinitializePasswordFormBuilderService: ReinitializePasswordFormBuilderService,
    public sendDataService: SendDataService,
    public loadDataService: LoadDataService,
    private userDataService: UserDataService,
    private storeService: StoreService,
    private router: Router) { }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.innerWidth = window.innerWidth;
      var screenSize;
      if (this.innerWidth > 850) {
        screenSize = 'big'
      }
      else { 
        screenSize = 'small'
      }
      this.setCssClassForHeader (screenSize); 
    }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;    
    var screenSize;
    if (this.innerWidth > 850)
      screenSize = 'big'
    else 
      screenSize = 'small'

    this.setCssClassForHeader (screenSize); 

    this.reinitializePasswordFormBuilderService.reinitializePasswordFormGroup = this.reinitializePasswordFormBuilderService.build();
    this.loadDataService.loadCaptcha ();
  }
  
  public setCssClassForHeader (screenSize) {
      if ( screenSize === 'big')
      {
          this.authentication_header_background = "authentication-header-background";
      }
      else 
      {
          this.authentication_header_background = "authentication-small-header-background";
      }
  }

  initPassword () {
    this.buttonIsDisabled = true
      const account = new DroneMatchAccount (null,
            this.reinitializePasswordFormBuilderService.email.value, 
              this.captcha, 
                this.robot);
      this.sendDataService.reinitializePassword (account)
        .then((result) => {
          if (result === 'UPDATED') {
            this.successMessageActived = true; 
            setTimeout(function() {
              this.successMessageActived = false;
              this.buttonIsDisabled=false; 
              this.router.navigate(['/login'],{queryParams: {email: this.reinitializePasswordFormBuilderService.email.value}})
            }.bind(this), 6000);
          }
        },
        (error) => {
          this.errorMessageActived = true; 
          this.textError = error.error.message
          this.buttonIsDisabled = true
             setTimeout(function() {
              this.buttonIsDisabled = false
              setTimeout(function() {
                this.errorMessageActived = false; 
                this.textError = '' 
              }.bind(this), 3000);
            }.bind(this), 1000);
          this.loadDataService.loadCaptcha ();
          this.captcha = '';
          this.robot=true;
        }
      );
  }  

  getEmailformControl() {
    return this.reinitializePasswordFormBuilderService.email;
  }

  getImage() {
    return this.storeService.dataStore.captcha.img;
  }

  getValue($event) {
    this.captcha = $event;
  }

  refreshImage() {
    this.loadDataService.loadCaptcha ();
  }

  iamNotARobotUpdate($event) {
    this.robot = $event;
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { JobAnnouncement } from 'src/app/model/job-announcement';
import { JobAnnouncementDataService } from 'src/app/services/data/job-announcement-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Region } from 'src/app/model/region';
import { LoadDataService } from 'src/app/services/data/load-data.service';
import {MatSort, Sort} from '@angular/material/sort';
import { Collectivity } from 'src/app/model/collectivity';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-find-job-announcement',
  templateUrl: './find-job-announcement.component.html',
  styleUrls: ['./find-job-announcement.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
})
export class FindJobAnnouncementComponent implements OnInit {

  
  displayedColumns: string[] = jobAnnouncementColumnsForExternalHomePage.map((col) => col.key);
  columnsSchema: any = jobAnnouncementColumnsForExternalHomePage;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('matSort') empTbSort = new MatSort();
  @ViewChild(MatSort) sort: MatSort;
  showInternal = false; 
  dataSource = new MatTableDataSource<JobAnnouncement>();
  myControl = new FormControl('');
  regions = new Map<string, string>([]);
  expandedElement: JobAnnouncement | null;

  constructor(  private jobAnnouncementDataService : JobAnnouncementDataService, 
                public loadDataService: LoadDataService,
                private router : Router,
                protected route: ActivatedRoute ) { }

  ngOnInit(): void {


      // if we are trying to show home page 
      // if (this.router.url === '/') {
          this.displayedColumns = jobAnnouncementColumnsForExternalHomePage.map((col) => col.key);
          this.columnsSchema = jobAnnouncementColumnsForExternalHomePage;
          this.showInternal = false;
      // }
      // else {
      //     // if pilote is connected and try to find jobAnnoucnement details 
      //     this.displayedColumns = jobAnnouncementColumns.map((col) => col.key);
      //     this.columnsSchema = jobAnnouncementColumns;
      //     this.showInternal = true;
      // }
      this.dataSource.data = [];
      this.dataSource.paginator = this.paginator;
      this.myControl.valueChanges.subscribe( (findStr) => {
        this.findJobWith (findStr);
      });

      // Get all regions and put them as a map 
      this.loadDataService.loadRegions().then ((regions) => {
        regions.map(region => {
          this.regions.set(region.code, region.nom);
        })
        this.findJobWith("");
      });
  }

  findJobWith ( str : string ) {
      this.jobAnnouncementDataService.findJobAnnouncements('', str,'' , '', '', 'false').subscribe((res: any) => {
          res.forEach ((jobAnnouncement)  => {
            jobAnnouncement.region = this.setRightRegionName (jobAnnouncement.region); 
          });
          res.sort((first, second) => {
            if (first.startingdate == null || second.startingdate == null) {
              return -1;
            }
            return new Date(second.startingdate).getTime() - new Date(first.startingdate).getTime();
          });
          this.dataSource.data = res;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      });
  }

  setRightRegionName (r) : string  {
    return this.regions.get(r);
  }


  onSelectCollectivity(event) {
      var collectivity = event as Collectivity;
      var tags = '', region= '', department= '', zipcode= '', city= '';
      
      if (collectivity.type === 'region')
      {
        region = collectivity.zipcode[0]
      }
      else {
        if (collectivity.type === 'dept')
        {
          department = collectivity.zipcode[0]
        } 
        else
        {
          zipcode = collectivity.zipcode[0]
        }
      }
    
      this.jobAnnouncementDataService
            .findJobAnnouncements(tags, region, department, zipcode, city,'false').subscribe((res: any) => {
              res.sort((first, second) => {
                if (first.startingdate == null || second.startingdate == null) {
                  return -1;
                }
                return new Date(second.startingdate).getTime() - new Date(first.startingdate).getTime();
              });
              this.dataSource.data = res;
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
      });
  }

  onSelectRegion(event) {
    var region = event as Region;
    this.jobAnnouncementDataService
          .findJobAnnouncements('', region.code, '', '', '', 'false').subscribe((res: any) => {
              res.forEach ((jobAnnouncement)  => {
                  jobAnnouncement.region = this.setRightRegionName (jobAnnouncement.region); 
              });
              res.sort((first, second) => {
                if (first.startingdate == null || second.startingdate == null) {
                  return -1;
                }
                return new Date(second.startingdate).getTime() - new Date(first.startingdate).getTime();
              });
              this.dataSource.data = res;
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
          });
  }


  goToJobAnnouncement(row) {
    this.router.navigate(['my-home/display-job-announcement', row.id]);
  }

  isNew(element) {
    const twoDaysAgo = new Date();
    // compare with two days ago last day is not included
    // Ex 10/10/2023 ==> jobAnnouncement having createddate 10/10/2023 and 09/102023 and 08/10/2023 
    // are selected as new 
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
    return new Date(element.createdate).getTime() > twoDaysAgo.getTime();
  }
}

// export const jobAnnouncementColumns = [
//   {
//     key: 'title',
//     type: 'text',
//     label: 'Titre',
//     required: true,
//   },
//   {
//     key: 'city',
//     type: 'text',
//     label: 'Ville',
//   },
//   {
//     key: 'startingdate',
//     type: 'date',
//     label: 'Date Début',
//   },
//   {
//     key: 'expand',
//     type: 'expand',
//     label: '',
//   },
//   {
//     key: 'quotation',
//     type: 'button',
//     label: 'Créer devis',
//   }
// ];


export const jobAnnouncementColumnsForExternalHomePage = [
  {
    key: 'title',
    type: 'text',
    label: 'Titre',
    required: true,
  },
  {
    key: 'region',
    type: 'text',
    label: 'Région',
  },
  {
    key: 'badge',
    type: 'badge',
    label: 'Statut',
  }
];

import { Component, OnInit } from '@angular/core';
import { LoadDataService } from 'src/app/services/data/load-data.service';
import { StoreService } from 'src/app/services/store/store.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  title = 'dronematch';

  constructor ( 
        public loadDataService : LoadDataService,
        public storeService: StoreService,
        public location: Location, 
      ) {
  }

  ngOnInit() {

  }

  clickit(divname, tabname){
    const el = document.getElementById(divname);
    const tab = document.getElementById(tabname);

    if (el.style.display === 'block') {
      el.style.display = 'none'
      tab.style.display = 'block'
    }
    else 
    {
      tab.style.display = 'none'
      el.style.display = 'block'
    }  
  }

}

import { Input,  Component } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';
@Component({
  selector: 'app-dm-dialog',
  templateUrl: './dm-dialog.component.html',
  styleUrls: ['./dm-dialog.component.css']
})
export class DmDialogComponent {

  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
              public dialogRef: MatDialogRef<DmDialogComponent>) {

  }

  ngOnInit() {
    console.log(this.data)
  }      
  
  valider() {
      this.dialogRef.close("validate");
  }

  close() {
      this.dialogRef.close("cancel");
  }
}

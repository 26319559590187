import { zip } from "rxjs";

/**
 * represent profile of connected user
 */
 export class DmUser {
    

    id: number; 

    /**
     * firstname
     */
    firstname : string;
     /**
      * lastname
      */
    lastname : string;
     
    /**
     * adress
     */
    num : string;
    road : string;
    additionalinfo :string
    city: string
    zipcode: string


    constructor(id: number, firstname: string, lastname: string, num: string,
        road: string, addtionalinfo: string, city: string, zipcode: string) {
            this.id = id;
            this.firstname = firstname;
            this.lastname = lastname;
            this.num = num;
            this.road = road;
            this.additionalinfo = addtionalinfo;
            this.city = city;
            this.zipcode = zipcode;
    }

}
  
import { Injectable } from '@angular/core';
import { Company } from 'src/app/model/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyFormInitService {

  constructor() { }

    /**
   * create the gabarit of the form and initialize it 
   * 
   *  * initialize all form controls and all controls validators
   * 
   * @returns 
   * 
   */
     init = (): {[key: string]: any;} => {
      return {
        companyname: ['BTP SUD'],
        email: ['btpsud@btpsud.com'],
        phone: ['06 86 54 78 89'],
        siret: ['825 452 21'], 
        siren: ['825 452 01 666'], 
        contactway: ['phone'],
        iban: ['FR76 4000 5001 2444 8995 5575 21'],
        bic: ['FTXXXBNP'],
        createdate: [], 
        updatedate: []
      }; 
    }
  
    initForUpdate = (company : Company ): {[key: string]: any;} => {
      return {
        companyname: [company.companyname],
        email: [company.email],
        phone: [company.phone],
        siret: [company.siret], 
        siren: [company.siren], 
        contactway: [company.contactway],
        iban: [company.iban],
        bic: [company.bic],
        createdate: [company.createdate], 
        updatedate: [company.updatedate]
      }; 
    }

}

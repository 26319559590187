import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { QuotationFormInitService } from './quotation-form-init.service';
import { QuotationLight } from 'src/app/model/quotation-light';
import { Quotation } from 'src/app/model/quotation';
import { JobAnnouncement } from 'src/app/model/job-announcement';

@Injectable({
  providedIn: 'root'
})
export class QuotationFormBuilderService {
  public quotationFormGroup : FormGroup;

  constructor( private quotationFormBuilder : FormBuilder, 
              public quotationFormInitService : QuotationFormInitService ) { }

  build = () : FormGroup => {
    return this.quotationFormBuilder.group (this.quotationFormInitService.init());
  }

  
  // buildForCreate = (jobAnnouncement:JobAnnouncement) : FormGroup => {
  //   return this.quotationFormBuilder.group (this.quotationFormInitService.initForCreate(jobAnnouncement));
  // }



  
  buildForCreate = (jobAnnouncementId:any) : FormGroup => {
    return this.quotationFormBuilder.group (this.quotationFormInitService.initForCreate(jobAnnouncementId));
  }

  buildForEdit = (quotation : Quotation) : FormGroup => {
    return this.quotationFormBuilder.group (this.quotationFormInitService.initForEdit(quotation));
  }

  buildForUpdate = (quotation : QuotationLight) : FormGroup => {
    return this.quotationFormBuilder.group (this.quotationFormInitService.initForUpdate(quotation));
  }

  public get idJobAnnouncement () : FormControl {
    return this.quotationFormGroup.get('idJobAnnouncement') as FormControl;
  }
  public get id () : FormControl {
    return this.quotationFormGroup.get('id') as FormControl;
  }

  public get idPilote () : FormControl {
    return this.quotationFormGroup.get('idPilote') as FormControl;
  }

  public get price () : FormControl {
    return this.quotationFormGroup.get('price') as FormControl;
  }

  public get availability () : FormControl {
    return this.quotationFormGroup.get('availability') as FormControl;
  }

  public get status () : FormControl {
    return this.quotationFormGroup.get('status') as FormControl;
  }

}

import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'src/app/services/cookie/cookie.service';
import { ShareDataService } from 'src/app/services/sharedata/share-data.service';
import { SidenavbarService } from 'src/app/services/side-navbar/side-navbar.service';


@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.css']
})
export class TopNavbarComponent implements OnInit {

  color = {'color':'white'};
  userIconColor = {'color':'white'};
  isLoguedIn = false;
  message:string;

  constructor ( private autenticationService: AuthenticationService,
    private cookieService: CookieService,
    private router : Router,
    private sidenavbarService: SidenavbarService, private data: ShareDataService ) {
    this.isLoguedIn = this.autenticationService.isLoggedIn();
    this.data.currentMessage.subscribe(message => this.message = message);
  }
  
  ngOnInit(): void {
  }
  
  public lockUnlockSideNavBar () {
		this.sidenavbarService.lockUnlockSideNavBar ();
    if (this.color.color == '#cdf76c')
    {
      this.color = {'color':'white'};
    }
    else 
    {
      this.color = {'color':'#cdf76c'};
    }
	}

  getUserName () {
    if (this.isLoguedIn)
    {
      const username = this.autenticationService.getUserName();
      return username; 
    }
    else {
      return '';   
    }
  }

  logout() {        
    this.isLoguedIn = false;
    this.data.changeMessage("Others")
    this.autenticationService.logout(); 
  }

  editProfileAction () {
    this.router.navigate(['my-home/my-profile'])
  }
  togglemobilemenu(){
    var x = document.getElementById("mobileMenuToggle");
        if (x.style.display === "block") {
            x.style.display = "none";
        } else {
            x.style.display = "block";
        }
  }
}

/**
 * represent an account to create 
 */
 export class DroneMatchAccount {
    
  /**
   * Pilote /  client
   */
    type : string;
    /*
     * Le image au format base64 
     */
    email: string;
    /**
     * captcha 
     * NFP : non functional prop 
     */
    captcha : string;

    /**
     * NFP
     */
    robot : Boolean;



    constructor(type: string, email: string, captcha : string, robot : Boolean) {
      this.type = type;
      this.email = email;
      this.captcha = captcha;
      this.robot = robot;
    }
}

/**
 * represent a quotation proposal 
 */
 export class QuotationLight {
   
   quotationId:string;
   idJobAnnouncement:string;
   price: number;
   availability: Date;

   constructor (
      quotationId:string,
      idJobAnnouncement: string,
      price: number,
      availability: Date,
    )
   {
      this.quotationId=quotationId;
      this.idJobAnnouncement=idJobAnnouncement;
      this.price=price; 
      this.availability=availability;
   }
}
  
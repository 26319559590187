import { Component, EventEmitter, OnInit, Input, Output, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoadDataService } from 'src/app/services/data/load-data.service';
import { SendDataService } from 'src/app/services/data/send-data.service';
import { StoreService } from 'src/app/services/store/store.service';
import {Observable} from 'rxjs';
import { City } from 'src/app/model/city';
import { CityDto } from 'src/app/model/city-dto';
@Component({
  selector: 'app-city-zipcode',
  templateUrl: './city-zipcode.component.html',
  styleUrls: ['./city-zipcode.component.css']
})
export class CityZipcodeComponent implements OnInit, OnChanges {

  // used in edit case => come from parent component  
  @Input () cityName;

  // used in edit case  => come from parent component 
  @Input () zipCode
  
  cities : City [] = [];
  tmpcities : City [] = [];
  flatZipCodes : string [] = [];

  myControl = new FormControl('');
  filteredOptions: Observable<City[]>;
  
  @Output ()
  onSelectCity : EventEmitter<CityDto> = new EventEmitter<CityDto>();

  constructor(public sendDataService: SendDataService,
    public loadDataService: LoadDataService,
    public storeService: StoreService,
    ) { }

  ngOnInit(): void {
       if (this.cityName && this.zipCode) {
        const city = new City(this.cityName, this.zipCode); 
        this.myControl.setValue (city) 
      }
      this.myControl.valueChanges.subscribe( (findStr) => {
          this.autoCompleteCity (findStr);   
        }
      );
  }

   
  ngOnChanges() {
    const city = new City(this.cityName, this.zipCode); 
    this.myControl.setValue (city) 
  }

  public setValue (selectedname, selectedcode) {
    this.zipCode = selectedcode
    this.cityName = selectedname
    const city = new City(this.cityName, this.zipCode); 
    this.myControl.setValue (city) 
  }

  autoCompleteCity (findStr : string) {
    if (typeof findStr === 'string') {
        //findStr = findStr.toLowerCase();
        if (findStr.length > 1) {
            if (findStr.length < 3) {
                this.loadDataService.loadCities (findStr).then ((cities) => {
                  console.log(cities);
                this.cities=cities;
                this.tmpcities = cities;
              });
            }
            else {
              this.cities = this.tmpcities.filter(city => city.codesPostaux[0].includes(findStr));
            }
        }
    }
  }

  display (city : any) {
      return city.codesPostaux[0];
  }

  filterCity (event) {
    console.log (event.source.value);
    const selectedCity = event.source.value as City; 
    const selectedCityDto = new CityDto ("city-by-dept", selectedCity, '',selectedCity.codesPostaux[0],'','','',''); 
    this.onSelectCity.emit (selectedCityDto);
  }
}

/**
 * represent city
 */
 export class City {
    
   nom : string;
   code : string;
   codesPostaux : Array<string>;
   codeDepartement: string;
   codeRegion: string;

   constructor ( nom : string, zipCode : string)
   {
        this.nom=nom; 
        this.codesPostaux=[];
        this.codesPostaux.push(zipCode);
   }
}
  
/**
 * represent token for logged user
 */
 export class TokenInfo {
    
    /**
     * access_token
     */
    access_token : string;
     /**
     * token_type
     */
    token_type : string;
     /**
     * refresh_token
     */
    refresh_token : string;
    /**
     * expires_in
     */
    expires_in : string;
    /**
     * scope
     */
    scope : string;

    constructor(access_token : string, token_type : string, refresh_token : string, expires_in : string, scope : string) {
        this.access_token = access_token;
        this.token_type = token_type;
        this.refresh_token = refresh_token;
        this.expires_in = expires_in;
        this.scope = scope;
    }
}
  
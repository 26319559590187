import { Component, EventEmitter, OnInit, Input, Output, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoadDataService } from 'src/app/services/data/load-data.service';
import { SendDataService } from 'src/app/services/data/send-data.service';
import { StoreService } from 'src/app/services/store/store.service';
import {Observable} from 'rxjs';
import { City } from 'src/app/model/city';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CityDto } from 'src/app/model/city-dto';
@Component({
  selector: 'app-city-name',
  templateUrl: './city-name.component.html',
  styleUrls: ['./city-name.component.css']
})
export class CityNameComponent implements OnInit, OnChanges  {

   // used in edit case => come from parent component  
   @Input () cityName;
 
   // used in edit case  => come from parent component 
   @Input () zipCode
   
   cities : City [] = [];
   tmpcities : City [] = [];
 
   myControl = new FormControl('');
   filteredOptions: Observable<City[]>;
   
   @Output ()
   onSelectCity : EventEmitter<CityDto> = new EventEmitter<CityDto>();

 
   constructor(public sendDataService: SendDataService,
     public loadDataService: LoadDataService,
     public storeService: StoreService,
     ) { }
 
   ngOnInit(): void {
        if (this.cityName && this.zipCode) {
          const city = new City(this.cityName, this.zipCode); 
          this.myControl.setValue (city) 
        }
       this.myControl.valueChanges.subscribe( (findStr) => {
            this.autoCompleteCity (findStr);   
         }
       );
   }
 
  ngOnChanges() {
    const city = new City(this.cityName, this.zipCode); 
    this.myControl.setValue (city) 
  }

   autoCompleteCity (findStr : any) {
      if (typeof findStr === 'string') {
        findStr = findStr.toLowerCase();
        if (findStr.length > 1) {
          if (findStr.length < 3) {
              this.loadDataService.loadCitiesByNamePrefix (findStr).then ((cities) => {
                this.cities=cities;
                this.tmpcities = cities;
              });
          }
          else {
              this.cities = this.tmpcities.filter(city => city.nom.toLowerCase().includes(findStr));
          }
        }
      }
   }
 
   public setValue (selectedname, selectedcode) {
      this.zipCode = selectedcode
      this.cityName = selectedname
      const city = new City(this.cityName, this.zipCode); 
      this.myControl.setValue (city) 
  }

   display (city : any) {
      return city.nom;
   }
 
   filterCity (event) {
     console.log (event.source.value);
     const selectedCity = event.source.value as City; 
     const selectedCityDto = new CityDto ("city-by-name", selectedCity, '','','','','',''); 
     this.onSelectCity.emit (selectedCityDto);
   }
 
}

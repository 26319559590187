import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CreateAccountInitService } from './create-account-init.service';

@Injectable({
  providedIn: 'root'
})
export class CreateAccountFormBuilderService {

  public createAccount : FormGroup;

  constructor(private createAccountFormBuilder : FormBuilder, public createAccountInitService : CreateAccountInitService) { }

  
  build = () : FormGroup => {
    return this.createAccountFormBuilder.group (this.createAccountInitService.init());
  }

  public get email () : FormControl {
    return this.createAccount.get('emailFormControl') as FormControl;
  }
}

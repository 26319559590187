import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from 'src/app/services/cookie/cookie.service';
import { PilotMaterial } from 'src/app/model/pilot-material';
import { AuthenticationService } from '../authentication/authentication.service';
import { AbstractDataService } from './abstract.-data.service';

@Injectable({
  providedIn: 'root',
})
export class PilotMaterialDataService extends AbstractDataService {

  private serviceUrl = this.DRONE_MATCH_CONFIGURATION.EndpointsApi.material;

  constructor (  private http: HttpClient,  
                public cookieService: CookieService, 
                public authenticationService: AuthenticationService ) {
    super();
  }


  getMaterials(): Observable<PilotMaterial[]> {
    return this.http
      .get(this.serviceUrl)
      .pipe<PilotMaterial[]>(map((data: any) => data));
  }

  public getPilotMaterialById(id: number): Promise<any> {

    return this.http.get(this.serviceUrl+`/${id}`)
      .toPromise()
      .then((material) => {
        return material;
      });
  }


  public deleteMaterial(id: number): Observable<number> {
    return this.http.delete<PilotMaterial>(
        `${this.serviceUrl}/${id}`)
        .pipe<number>(map((result: any) => result));
  }


    // create material 
    public createMaterial (material: any): Promise<any> {
      
      return this.http.post ( 
                this.serviceUrl, 
                material)
        .toPromise ()
        .then (
          result => {
            return result;
          }
        );
    }
}

import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { AuthenticationService } from '../services/authentication/authentication.service';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { TokenInfo } from '../model/token-info';
import { ShareDataService } from '../services/sharedata/share-data.service';

const TOKEN_HEADER_KEY = 'Authorization'; 

@Injectable()
export class AuthanticationHttpInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthenticationService, private data: ShareDataService ) { }

  /**
   * all request that requestUrl stats with /start ==> does not need authntication 
   *  ==> live it and let it passes to the next 
   * 
   * otherwise, all other requests need authentication 
   *  ==> Get the token from cookie and put it on the header of the request 
   *  ==> start the request 
   *      * => if it is fulfilled ==> its okay 
   *      * => Otherwise :  
   *            - => if access_token is expired and request failed with 401 (unauthorized) ==> try to refresh token 
   *            - => if refresh token is expired and request failed with even 401, 500 ==> logout directly
   *            - => if refresh token operation succed => it is okay evey thing is okay 
   * @param req request 
   * @param next next handler (in our case 1°) request processing / or 2°) logout / or throw an error
   * @returns http event forwarded to next httphandler  
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;
    if (!authReq.url.includes('/start')) {
      const token = this.authService.getAccessToken ();
      if (token != null && token !== '') {
        authReq = this.addTokenHeader(req, token);
      }

      return next.handle(authReq).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handle401Error(authReq, next);
        }

        return throwError(error);
      }));
    }
    return next.handle(authReq);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.authService.getRefreshToken ();

      if (token)
        return this.authService.startRefreshToken(token).pipe(
          switchMap((tokenInfo: any) => {
            const ti  = tokenInfo as TokenInfo; 
            this.authService.setAccessToken (ti.access_token);
            this.authService.setRefreshToken (ti.refresh_token);
            this.isRefreshing = false;
            this.refreshTokenSubject.next(ti.access_token);
            return next.handle(this.addTokenHeader(request, ti.access_token));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.data.changeMessage("Others");
            setTimeout(function() {
            }.bind(this), 1000);
            this.authService.logout();
            return throwError(err);
          })
        );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthanticationHttpInterceptor, multi: true }
];